import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[OnlyNumber]'
})
export class NumericDirective {
    // Allow decimal numbers and negative values
    private _regex: RegExp = new RegExp('^[-]?[0-9]{0,9}(?:\\.[0-9]{0,2})?$');
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private _specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    constructor(private _el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        // Allow Backspace, tab, end, and home keys
        if (this._specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this._el.nativeElement.value;
        const position = this._el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
        if (next && !String(next).match(this._regex)) {
            event.preventDefault();
        }
    }
}
