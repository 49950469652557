import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CorePipesModule} from './pipes/pipe.module';
import {MatIconModule} from '@angular/material/icon';
import {CoreProgressBarModule} from '../../@core/components/progress-bar/progress-bar.module';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgxMaskModule} from 'ngx-mask';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {HttpClientModule} from '@angular/common/http';
import {CoreFullscreenModule} from '../../@core/components/fullscreen';
import {CoreNavigationModule} from '../../@core/components/navigation';
import {NgxConcentricModule} from '@ai/ngx-concentric';
import {CoreLongPressModule} from '../../@core/directives/long-press';
import {RouterModule} from '@angular/router';
import {CoreDrawerModule} from '../../@core/components/drawer';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CorePipesModule,
        CoreProgressBarModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDividerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatTooltipModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatMenuModule,
        MatChipsModule,
        MatSlideToggleModule,
        NgxMaskModule,
        NgxMaterialTimepickerModule,
        HttpClientModule,
        CoreFullscreenModule,
        CoreNavigationModule,
        NgxConcentricModule,
        CoreLongPressModule,
        CoreDrawerModule,
        MatRadioModule,
    ],
    exports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CorePipesModule,
        CoreProgressBarModule,
        MatButtonModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatDividerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatIconModule,
        MatTooltipModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatMenuModule,
        MatChipsModule,
        MatSlideToggleModule,
        NgxMaskModule,
        NgxMaterialTimepickerModule,
        HttpClientModule,
        CoreFullscreenModule,
        CoreNavigationModule,
        NgxConcentricModule,
        CoreLongPressModule,
        CoreDrawerModule,
        MatRadioModule,
    ]
})
export class SharedModule {
}
