import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[longPress]',
    exportAs: 'longPress'
})
export class CoreLongPressDirective {
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onLongPressing = new EventEmitter();
    longPressing: boolean;
    timeout: any;
    interval: any;

    @HostListener('touchstart', ['$event'])
    @HostListener('mousedown', ['$event'])
    onMouseDown(event): void {
        this.longPressing = false;
        this.timeout = setTimeout(() => {
            this.longPressing = true;
            this.interval = setInterval(() => {
                this.onLongPressing.emit(event);
            }, 50);
        }, 500);
    }

    @HostListener('touchend')
    @HostListener('mouseup')
    @HostListener('mouseleave')
    endPress(): void {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
        this.longPressing = false;
    }
}
