import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CoreFullscreenComponent} from '@core/components/fullscreen/fullscreen.component';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [
        CoreFullscreenComponent
    ],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        CommonModule
    ],
    exports: [
        CoreFullscreenComponent
    ]
})
export class CoreFullscreenModule {
}
