import {Injectable} from '@angular/core';
import {ApiService, Response} from '@ai/ngx-concentric';
import {Unit} from '../models/unit.model';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import {ChannelReadingsViewModel} from '../../modules/admin/readings-and-settings/readings/readings.model';
// @ts-ignore
const parseString = require('xml2js').parseString;

@Injectable({
    providedIn: 'root'
})
export class UnitService {

    constructor(private _apiService: ApiService,
                private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------
    // Gets the unit by Id
    // -----------------------------------------------------------------
    getUnitId(passedInUnitId?: number): number {
        let unitId: number;
        // If the unit Id is passed in, use that value
        if (passedInUnitId) {
            unitId = passedInUnitId;
        } else {
            // If the unit Id was not passed in, attempt to get it from local storage
            if (localStorage.getItem('selectedUnitId') !== null) {
                unitId = +localStorage.getItem('selectedUnitId');
            }
        }
        return unitId;
    }

    // -----------------------------------------------------------------
    // Gets the unit by Id
    // -----------------------------------------------------------------
    getUnitLookUpUrl(): string {
        if (this.getUnitId()) {
            return `${environment.bullhornApiUrl}/Units/GetRm5UnitByIdAsync?unitId=${this.getUnitId()}`;
        }
        return null;
    }

    // -----------------------------------------------------------------
    // Gets the unit by Id
    // -----------------------------------------------------------------
    async getUnitByIdAsync(passedInUnitId?: number): Promise<Unit> {
        let unitId: number;
        // If the unit Id is passed in, use that value
        if (passedInUnitId) {
            unitId = passedInUnitId;
        } else {
            // If the unit Id was not passed in, attempt to get it from local storage
            if (localStorage.getItem('selectedUnitId') !== null) {
                unitId = +localStorage.getItem('selectedUnitId');
            }
        }
        // Only when a unit Id is provided, invoke HTTP request
        if (unitId) {
            const request = await this._apiService.getAsync<Response<Unit>>(`${environment.bullhornApiUrl}/Units/GetRm5UnitByIdAsync?unitId=${unitId}`).then();
            return this.parseUnit(request.result);
        }
        return null;
    }

    // -----------------------------------------------------------------
    // Gets the unit using either the activated route or a passed in unit Id
    // -----------------------------------------------------------------
    async getUnitAsync(passedInUnitId?: number, activatedRoute?: ActivatedRoute): Promise<Unit> {
        // Check if the unit can be accessed through the resolver
        if (activatedRoute !== undefined && activatedRoute.snapshot.data['initialData']) {
            if (activatedRoute.snapshot.data['initialData'][2]) {
                const unitResponse = activatedRoute.snapshot.data['initialData'][2] as Response<Unit>;
                return this.parseUnit(unitResponse.result);
            }
        } else {
            // In the event the MFE in BHW cant access a resolver, fallback to async query
            // Check if the unit can be accessed through the service
            return await this.getUnitByIdAsync(passedInUnitId).then();
        }
    }

    // -----------------------------------------------------------------
    // Gets the channel readings unit data points
    // -----------------------------------------------------------------
    async getChannelReadingsAsync(unitId: number, instanceId: number): Promise<ChannelReadingsViewModel[]> {
        if (!unitId) {
            return [];
        }
        const request = await this._apiService.getAsync<Response<ChannelReadingsViewModel[]>>(`${environment.bullhornApiUrl}/UnitDataPoints/GetChannelReadingsAsync?unitId=${unitId}&instanceId=${instanceId}`).then();
        return request.result;
    }

    // -----------------------------------------------------------------
    // Gets the channel reading unit data point by unit ID and Unit Data Point Id
    // -----------------------------------------------------------------
    async getChannelReadingByIdAsync(unitId: number, dataPointId: number, instanceId: number): Promise<ChannelReadingsViewModel> {
        if (!unitId) {
            return null;
        }
        const request = await this._apiService.getAsync<Response<ChannelReadingsViewModel>>(`${environment.bullhornApiUrl}/UnitDataPoints/GetUnitDataPointAsync?unitId=${unitId}&dataPointId=${dataPointId}&instanceId=${instanceId}`).then();
        return request.result;
    }

    // -----------------------------------------------------------------
    // Determines whether a unit has reported properties.
    // -----------------------------------------------------------------
    validateHasReportedProperties(unit: Unit): boolean {
        return unit.unitSettings['properties']['reported'] !== undefined;
    }

    // -----------------------------------------------------------------
    // Parses the unit's unitSettings to JSON
    // -----------------------------------------------------------------
    parseUnit(unit): Unit {
        unit.unitSettings = JSON.parse(unit.unitSettings);
        return unit;
    }
}
