import {Injectable} from '@angular/core';
import {InterruptionModeEnum} from './interruption.enums';
import {ApiService, ArrayService, Dictionary} from '@ai/ngx-concentric';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormService} from '../../../core/services/form.service';
import * as moment from 'moment';
import {DatePipe} from '@angular/common';
import {Unit} from '@ai/ngx-concentric/lib/models/unit';
import {InterruptionFormModel, InterruptionFormResponse, PendingInterruptionModel} from './interruption.models';
import * as _ from 'lodash';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {TimeZone} from 'app/core/models/time-zone';
import {Response} from '@ai/ngx-concentric/lib/models/response';
import { RM5AnalogChannels } from '../readings-and-settings/readings/components/channel/channel.models';
import { UnitStyleEnum } from 'app/core/enums/unit-style.enum';
import { ActivatedRoute } from '@angular/router';
import momentTZ from 'moment-timezone';
import { BrandType } from 'app/core/enums/brand-type.enum';
import { ChannelId } from 'app/core/enums/channel-Ids.enum';

@Injectable({
    providedIn: 'root'
})
export class InterruptionService {
    public interruptionModeEnum = InterruptionModeEnum;
    public formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    });

    constructor(private _activatedRoute: ActivatedRoute,
                private _arrayService: ArrayService,
                private _apiService: ApiService,
                private _datePipe: DatePipe,
                private _formService: FormService,
                private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------
    // Returns array of interruption modes
    // -----------------------------------------------------------------
    public getInterruptionModes(): Dictionary<string, number>[] {
        return this._arrayService.GetArrayFromEnum<string, number>(InterruptionModeEnum);
    }

    // -----------------------------------------------------------------
    // Watch form control value changes
    // -----------------------------------------------------------------
    public subscribeToFormChanges(form: FormGroup): FormGroup {
        // Start Date
        if (form.get('start_date_10')?.value) {
            form.get('start_date_10').valueChanges.subscribe(() => {
                form = this.validateDateTimes(form);
            });
        }

        // Start Time
        if (form.get('start_time_8')?.value) {
            form.get('start_time_8').valueChanges.subscribe(() => {
                form = this.validateStartStopTimes(form);
            });
        }

        // Stop Date
        if (form.get('stop_date_11')?.value) {
            form.get('stop_date_11').valueChanges.subscribe(() => {
                form = this.validateDateTimes(form);
            });
        }

        // Stop Time
        if (form.get('stop_time_9')?.value) {
            form.get('stop_time_9').valueChanges.subscribe(() => {
                form = this.validateStartStopTimes(form);
            });
        }

        // On Time
        if (form.get('on_time_1')?.value) {
            form.get('on_time_1').valueChanges.subscribe((value) => {
                this.validateOnOffTimes(form);
            });
        }

        // Off Time
        if (form.get('off_time_2')?.value) {
            form.get('off_time_2').valueChanges.subscribe((value) => {
                this.validateOnOffTimes(form);
            });
        }
        return form;
    }

    // -----------------------------------------------------------------
    // Creates the hint based on the interruption mode
    // -----------------------------------------------------------------
    public getInterruptionModeHint(interruptionMode: InterruptionModeEnum): Dictionary<string, string> {
        if (interruptionMode === InterruptionModeEnum.interruptionOff) {
            return {key: 'Interruption Off', value: 'Interruption is not occurring'};
        }
        else if (interruptionMode === InterruptionModeEnum.startStop) {
            return {
                key: 'Start Stop',
                value: 'Interruption occurs every day between the specified start & end times for the given date range'
            };
        }
        else if (interruptionMode === InterruptionModeEnum.continuous) {
            return {key: 'Continuous', value: 'Interruption occurs continuously (24/7)'};
        }
        else if (interruptionMode === InterruptionModeEnum.cathodicProtectionOff) {
            return {
                key: 'Cathodic Protection Off',
                value: 'Interruption is running and is continuously holding the relay open'
            };
        }
        // Nothing was found so just set it to the Interruption Off
        return {key: 'Interruption Off', value: 'Interruption is not occurring'};
    }

    // -----------------------------------------------------------------
    // Creates the hint based on the interruption mode
    // -----------------------------------------------------------------
    public UpdateForm(value: InterruptionModeEnum, form: FormGroup): FormGroup {

        // Update validation when start/stop is set
        if (value === this.interruptionModeEnum.startStop) {
            this._formService.setValidatorsAndUpdateValidity(['start_date_10', 'start_time_8', 'stop_date_11', 'stop_time_9'], form, Validators.required);
            this.validateDateTimes(form);
        }

        // Update validation when continuous is set
        if (value === this.interruptionModeEnum.continuous) {
            this._formService.cleanValidatorsAndUpdateValidity(['start_date_10', 'start_time_8', 'stop_date_11', 'stop_time_9'], form);
        }

        // Update validation when alwaysOff is set
        if (value === this.interruptionModeEnum.cathodicProtectionOff) {
            this._formService.setValidatorsAndUpdateValidity(['start_date_10', 'start_time_8', 'stop_date_11', 'stop_time_9'], form, Validators.required);
        }

        // Update validation when off
        if (value === this.interruptionModeEnum.interruptionOff) {
            const formControlNames = ['on_time_1', 'off_time_2', 'output_mode_3', 'sync_mode_4', 'start_date_10', 'start_time_8', 'stop_date_11', 'stop_time_9'];
            this._formService.cleanValidatorsAndUpdateValidity(formControlNames, form);
        }

        this._formService.setValidatorsAndUpdateValidity(['upper_threshold_20'], form, [Validators.required, Validators.min(1), Validators.max(10000)]);
        return form;
    }

    // -----------------------------------------------------------------
    // Gets the time in seconds
    // -----------------------------------------------------------------
    public getTimeInSeconds(form: FormGroup, key: string): number {
        const startTimespan = moment(form.controls[key].value, ['h:mm A']).format('HH:mm').split(':');
        return (+startTimespan[0]) * 60 * 60 + (+startTimespan[1]);
    }

    // -----------------------------------------------------------------
    // Validates there is a time zone selected
    // -----------------------------------------------------------------
    public validateTimeZone(form: FormGroup, timeZoneForm: FormGroup, timezoneSelected: TimeZone): FormGroup {
        if (form.controls.interruption_mode_0.value !== InterruptionModeEnum.startStop) {
            if (form.controls?.offset_15) {
                form.controls?.offset_15.setErrors(null);
            }
            return form;
        }

        // If the form control does not exist add it
        if (!form.controls?.offset_15) {
            form.addControl('offset_15', new FormControl(null, Validators.required));
        }

        // Validate the control
        if (!timezoneSelected && (!form.controls.offset_15?.value || (!timeZoneForm.controls.timeZoneId?.value || !timeZoneForm.controls.timeZoneId?.value === null))) {
            form.controls.offset_15.setErrors({valueRequired: 'A time zone must be selected'});
            timeZoneForm.controls.timeZoneId.setErrors({valueRequired: 'A time zone must be selected'});
        }

        return form;
    }

    // -----------------------------------------------------------------
    // Ensures start date is less than stop date
    // -----------------------------------------------------------------
    public validateDateTimes(form: FormGroup): FormGroup {
        // Set time for interruption mode: start stop
        if (form.controls.interruption_mode_0.value === InterruptionModeEnum.startStop) {
            // Get stop date & time in UTC seconds
            const startDate = form.controls['start_date_10'].value;
            const startTime = form.controls['start_time_8'].value;
            const startDateTime = moment.utc(new Date(`${moment(startDate).format('MM/DD/YYYY')} ${startTime}`));

            // Get start date & time in UTC seconds
            const stopDate = form.controls['stop_date_11'].value;
            const stopTime = form.controls['stop_time_9'].value;
            const stopDateTime = moment.utc(new Date(`${moment(stopDate).format('MM/DD/YYYY')} ${stopTime}`));

            // Validate the start date controls
            form.controls['start_date_10'].setErrors(startDateTime >= stopDateTime ? {timeError: 'Start date & time must come before stop date & time'} : null);
            form.controls['start_time_8'].setErrors(startDateTime >= stopDateTime ? {timeError: 'Start date & time must come before stop date & time'} : null);

            // Validate the stop date controls
            form.controls['stop_date_11'].setErrors(stopDateTime <= startDateTime ? {timeError: 'Stop date & time must come after start date & time'} : null);
            form.controls['stop_time_9'].setErrors(stopDateTime <= startDateTime ? {timeError: 'Stop date & time must come after start date & time'} : null);
        }
        return form;
    }

    // -----------------------------------------------------------------
    // Validates the start and stop times
    // -----------------------------------------------------------------
    public validateStartStopTimes(form: FormGroup): FormGroup {
        if (form.controls.interruption_mode_0.value === InterruptionModeEnum.startStop) {
            this.validateDateTimes(form);
        }
        return form;
    }

    // -----------------------------------------------------------------
    // Validates the on/off times to ensure min of 1 second and max of
    // 10 seconds for on time or off time
    // -----------------------------------------------------------------
    public validateOnOffTimes(form: FormGroup): FormGroup {
        const onTime = form.controls['on_time_1']?.value === '' ? null : +form.controls['on_time_1']?.value as number;
        const offTime = form.controls['off_time_2']?.value === '' ? null : +form.controls['off_time_2']?.value as number;
        const totalTime = onTime + offTime;

        // Ignore on/off times
        if (form.controls.interruption_mode_0.value === InterruptionModeEnum.interruptionOff ||
            form.controls.interruption_mode_0.value === InterruptionModeEnum.cathodicProtectionOff) {
            return form;
        }

        const missingValue = 'A value is required.';
        if (onTime === null || offTime === null) {
            form.controls['on_time_1'].setErrors(onTime === null ? {'missingValue': missingValue} : null);
            form.controls['off_time_2'].setErrors(offTime === null ? {'missingValue': missingValue} : null);
            return form;
        }

        if (totalTime < 1) {
            const totalTimeLessThanOne = 'The total time combined for on and off time cannot be less than 1 second.';
            form.controls['on_time_1'].setErrors({'totalTimeLessThanOne': totalTimeLessThanOne});
            form.controls['off_time_2'].setErrors({'totalTimeLessThanOne': totalTimeLessThanOne});
            return form;
        }

        // Check if the sum of on and off times is greater than 10 seconds
        if (totalTime > 999.9) {
            const totalTimeGreaterThanAllowed = 'The total time combined for on and off time cannot exceed 999.9 seconds.';
            form.controls['on_time_1'].setErrors({'totalTimeGreaterThanAllowed': totalTimeGreaterThanAllowed});
            form.controls['off_time_2'].setErrors({'totalTimeGreaterThanAllowed': totalTimeGreaterThanAllowed});
            return form;
        }

        // All conditions were met. Clear the controls of errors
        form.controls['on_time_1'].setErrors(null);
        form.controls['off_time_2'].setErrors(null);
        return form;
    }

    // -----------------------------------------------------------------------------------------------------
    // Returns an array of channels based on Unit
    // -----------------------------------------------------------------------------------------------------
    getChannelsByUnit(unit: Unit): Dictionary<number, string>[] 
    {
        let analogChannels = RM5AnalogChannels.get(unit.brandId);
        let result = [];
        if (unit.brandId == BrandType.RM520C || unit.brandId == BrandType.RM520S) {
            analogChannels = analogChannels.filter(x => x.channelId != ChannelId.CH3_Shunt)
        }
        analogChannels.forEach(c => result.push({key: c.channelId, value: c.channelName}))
        return result;
    }

    public GetProperties(unit: Unit, instanceId: number): any {
        let desiredProperties = null;
        let reportedProperties = null;
        try{
            if (unit && unit.unitSettings) {

                let index = '0';
                if (unit.brand.unitStyleId == UnitStyleEnum.Dome || unit.brand.unitStyleId == UnitStyleEnum.IOModule)
                {
                    index = `100${instanceId}`;
                }
                desiredProperties = unit.unitSettings?.['properties']?.['desired']?.['ai_interrupt_31040']?.[index];
                reportedProperties = unit.unitSettings?.['properties']?.['reported']?.['ai_interrupt_31040']?.[index];
    
                if((!desiredProperties || !reportedProperties) && 
                (unit.unitSettings?.['properties']?.['desired']?.['ai_interrupt_31040']))
                    index = Object.keys(unit.unitSettings?.['properties']?.['desired']?.['ai_interrupt_31040'])[0];  

                if((!desiredProperties || !reportedProperties) && 
                (unit.unitSettings?.['properties']?.['reported']?.['ai_interrupt_31040']))
                    index = Object.keys(unit.unitSettings?.['properties']?.['reported']?.['ai_interrupt_31040'])[0];            
                
                if(!desiredProperties)
                    desiredProperties = unit.unitSettings?.['properties']?.['desired']?.['ai_interrupt_31040']?.[index];
                
                if(!reportedProperties)            
                    reportedProperties = unit.unitSettings?.['properties']?.['reported']?.['ai_interrupt_31040']?.[index];               
            }
        }
        catch (error) {
            console.error('Error getting properties', error);
        }
        return {desiredProperties, reportedProperties};
    }

    // -----------------------------------------------------------------
    // Helps build the form control values
    //  -----------------------------------------------------------------
    public getInterruptionFormControls(unit: Unit, instanceId: number): InterruptionFormResponse {
        let desiredProperties = null;
        let reportedProperties = null;

        let result = this.GetProperties(unit, instanceId);
        desiredProperties = result.desiredProperties;
        reportedProperties = result.reportedProperties; 
        
        const model: Partial<InterruptionFormModel> = {};
        const pendingModel: Partial<PendingInterruptionModel> = {};
        //------------------------------------------------------------------------------------------------
        // Interruption Mode
        //------------------------------------------------------------------------------------------------
        model.interruption_mode_0 = reportedProperties?.['interruption_mode_0'] ? +reportedProperties?.['interruption_mode_0'] : 0;
        if (desiredProperties?.['interruption_mode_0'] !== undefined) {
            pendingModel['interruption_mode_0'] = +desiredProperties['interruption_mode_0'] !== model['interruption_mode_0'];
        }

        //------------------------------------------------------------------------------------------------
        // On Time
        //------------------------------------------------------------------------------------------------
        model.on_time_1 = reportedProperties ? (+this.formatter.format((reportedProperties?.['on_time_1'] ?? 0)) / 10) : 0.90;
        if (desiredProperties?.['on_time_1'] !== undefined) {
            const desiredOnTime = +this.formatter.format((desiredProperties?.['on_time_1'] ?? 0)) / 10;
            pendingModel['on_time_1'] = desiredOnTime !== model['on_time_1'];
        }

        //------------------------------------------------------------------------------------------------
        // Off Time
        //------------------------------------------------------------------------------------------------
        model.off_time_2 = reportedProperties ? (+this.formatter.format((reportedProperties?.['off_time_2'] ?? 0)) / 10) : 0.10;
        if (desiredProperties?.['off_time_2'] !== undefined) {
            const desiredOffTime = +this.formatter.format((desiredProperties?.['off_time_2'] ?? 0)) / 10;
            pendingModel['off_time_2'] = desiredOffTime !== model['off_time_2'];
        }

        //------------------------------------------------------------------------------------------------
        // Output Mode
        //------------------------------------------------------------------------------------------------
        model.output_mode_3 = reportedProperties?.['output_mode_3'] ?? false;
        if (desiredProperties?.['output_mode_3'] !== undefined) {
            pendingModel['output_mode_3'] = desiredProperties['output_mode_3'] !== model['output_mode_3'];
        }

        //------------------------------------------------------------------------------------------------
        // Sync Mode
        //------------------------------------------------------------------------------------------------
        model.sync_mode_4 = reportedProperties?.['sync_mode_4'] ?? true;
        if (desiredProperties?.['sync_mode_4'] !== undefined) {
            pendingModel['sync_mode_4'] = desiredProperties['sync_mode_4'] !== model['sync_mode_4'];
        }

        //------------------------------------------------------------------------------------------------
        // Verification Interruption Enabled
        //------------------------------------------------------------------------------------------------
        model.verification_enabled_23 = reportedProperties?.['verification_enabled_23'] ?? false;
        if (desiredProperties?.['verification_enabled_23'] !== undefined) {
            pendingModel['verification_enabled_23'] = desiredProperties['verification_enabled_23'] !== model['verification_enabled_23'];
        }

        //------------------------------------------------------------------------------------------------
        // Verification Interruption Lower Threshold
        //------------------------------------------------------------------------------------------------
        model.lower_threshold_19 = reportedProperties?.['lower_threshold_19'] !== undefined ? reportedProperties?.['lower_threshold_19'] / 1000 : 1;
        if (desiredProperties?.['lower_threshold_19'] !== undefined) {
            pendingModel['lower_threshold_19'] = desiredProperties['lower_threshold_19'] !== reportedProperties?.['lower_threshold_19'];
        }

        //------------------------------------------------------------------------------------------------
        // Verification Interruption Upper Threshold
        //------------------------------------------------------------------------------------------------
        model.upper_threshold_20 = reportedProperties?.['upper_threshold_20'] !== undefined ? reportedProperties?.['upper_threshold_20'] / 1000 : 1;
        if (desiredProperties?.['upper_threshold_20'] !== undefined) {
            pendingModel['upper_threshold_20'] = desiredProperties['upper_threshold_20'] !== reportedProperties?.['upper_threshold_20'];
        }

        //------------------------------------------------------------------------------------------------
        // UTC Offset
        //------------------------------------------------------------------------------------------------
        model.offset_15 = reportedProperties?.['offset_15'] ?? null;
        if (desiredProperties?.['offset_15'] !== undefined) {
            pendingModel['offset_15'] = desiredProperties?.['offset_15'] !== model['offset_15'];
        }

        //------------------------------------------------------------------------------------------------
        // Start Time
        //------------------------------------------------------------------------------------------------
        model.start_time_8 = reportedProperties?.['start_time_8'] >= 0 ? reportedProperties?.['start_time_8'] : 0;
        if (reportedProperties?.['start_time_8'] >= 0) {
            model.start_time_8 = this.convertDecisecondsToTimeString(reportedProperties?.['start_date_10'], reportedProperties?.['start_time_8']);
        } else {
            model.start_time_8 = this._datePipe.transform(new Date(), 'HH:mm');
        }

        // Check pending value
        if (desiredProperties?.['start_time_8'] >= 0) {
            pendingModel['start_time_8'] = desiredProperties?.['start_time_8'] !== reportedProperties?.['start_time_8'];
        }

        //------------------------------------------------------------------------------------------------
        // Stop Time
        //------------------------------------------------------------------------------------------------
        model.stop_time_9 = reportedProperties?.['stop_time_9'] >= 0 ? reportedProperties?.['stop_time_9'] : 0;
        if (reportedProperties?.['stop_time_9'] >= 0) {
            model.stop_time_9 = this.convertDecisecondsToTimeString(reportedProperties?.['stop_date_11'], reportedProperties?.['stop_time_9']);
        } else {
            model.stop_time_9 = this._datePipe.transform(new Date(), 'HH:mm');
        }

        // Check pending value
        if (desiredProperties?.['stop_time_9'] >= 0) {
            pendingModel['stop_time_9'] = desiredProperties?.['stop_time_9'] !== reportedProperties?.['stop_time_9'];
        }

        //------------------------------------------------------------------------------------------------
        // Start Date
        //------------------------------------------------------------------------------------------------
        if (reportedProperties?.['start_date_10']) {
            const reportedlocalDateTime: Date = this.convertUTCToLocalTime((reportedProperties?.['start_date_10']).slice(0, 10), model.start_time_8, model.offset_15);
            model.start_date_10 = reportedlocalDateTime;
            const hours = reportedlocalDateTime.getHours();
            const mins = reportedlocalDateTime.getMinutes();
            model.start_time_8 = `${hours}:${mins}`;
        } else {
            model.start_date_10 = new Date();
        }

        if (desiredProperties && desiredProperties?.['start_date_10']) {
            const hourMin = this.convertDecisecondsToTimeString(desiredProperties?.['start_date_10'], desiredProperties?.['start_time_8']);
            const desiredStartDate = this.convertUTCToLocalTime((desiredProperties?.['start_date_10']).slice(0, 10), hourMin, model.offset_15);
            pendingModel['start_date_10'] = desiredStartDate.toString() !== model.start_date_10.toString();
        }

        //------------------------------------------------------------------------------------------------
        // Stop Date
        //------------------------------------------------------------------------------------------------
        if (reportedProperties?.['stop_date_11']) {
            const reportedlocalDateTime: Date = this.convertUTCToLocalTime((reportedProperties?.['stop_date_11']).slice(0, 10), model.stop_time_9, model.offset_15);
            model.stop_date_11 = reportedlocalDateTime;
            const hours = reportedlocalDateTime.getHours();
            const mins = reportedlocalDateTime.getMinutes();
            model.stop_time_9 = `${hours}:${mins}`;
        } else {
            model.stop_date_11 = new Date();
        }

        if (desiredProperties && desiredProperties?.['stop_date_11']) {
            const hourMin = this.convertDecisecondsToTimeString(desiredProperties?.['stop_date_11'], desiredProperties?.['stop_time_9']);
            const desiredStopDate = this.convertUTCToLocalTime((desiredProperties?.['stop_date_11']).slice(0, 10), hourMin, model.offset_15);
            pendingModel['stop_date_11'] = desiredStopDate.toString() !== model.stop_date_11.toString();
        }

        //------------------------------------------------------------------------------------------------
        // number of units
        // Number of Units in Interference Config, default is 1 which means no I&I study (solo interrupter), must set "Unit Number" appropriately as well]
        // Range Enumeration 1-99
        //------------------------------------------------------------------------------------------------
        model.number_of_units_5 = reportedProperties?.['number_of_units_5'] ?? 1;
        model.number_of_units_5 = model.number_of_units_5 < 1 ? 1 : model.number_of_units_5;
        if (desiredProperties?.['number_of_units_5'] !== undefined) {
            pendingModel['number_of_units_5'] = desiredProperties['number_of_units_5'] !== model['number_of_units_5'];
        }

        //------------------------------------------------------------------------------------------------
        // unit number
        // This unit's number in Interference and Influence study, default is 1 which means no I&I study (solo interrupter)
        // Range Enumeration 1-99
        //------------------------------------------------------------------------------------------------
        model.unit_number_6 = reportedProperties?.['unit_number_6'] ?? 1;
        model.unit_number_6 = model.unit_number_6 < 1 ? 1 : model.unit_number_6;
        if (desiredProperties?.['unit_number_6'] !== undefined) {
            pendingModel['unit_number_6'] = desiredProperties['unit_number_6'] !== model['unit_number_6'];
        }

        //------------------------------------------------------------------------------------------------
        // delay between units
        // Number of seconds between units in an Interference and Influence study
        // Range Enumeration 1-9999 represented as 1/10th of a second. IE: 1 second = 0.1 seconds
        //------------------------------------------------------------------------------------------------
        model.delay_between_units_7 = reportedProperties?.['delay_between_units_7'] ?? 1;
        model.delay_between_units_7 = model.delay_between_units_7 <= 1 ? 1 : (Math.round(model.delay_between_units_7) / 10);
        if (desiredProperties?.['delay_between_units_7'] !== undefined || desiredProperties?.['delay_between_units_7'] > 0) {
            pendingModel['delay_between_units_7'] = (+(desiredProperties['delay_between_units_7'] ?? 0) / 10) !== model['delay_between_units_7'];
        }

        //------------------------------------------------------------------------------------------------
        // interference enabled is defined by having more than 1 number of units in the I&I study
        //------------------------------------------------------------------------------------------------
        model.interference_enabled_100 = model.number_of_units_5 > 1;

        //------------------------------------------------------------------------------------------------
        // Instant-Off Enabled
        //------------------------------------------------------------------------------------------------
        model.instant_off_enabled_24 = reportedProperties?.['instant_off_enabled_24'] ?? false;
        if (desiredProperties?.['instant_off_enabled_24'] !== undefined) {
            pendingModel['instant_off_enabled_24'] = desiredProperties['instant_off_enabled_24'] !== model['instant_off_enabled_24'];
        }

        //------------------------------------------------------------------------------------------------
        // Instant-Off Channel
        //------------------------------------------------------------------------------------------------
        model.instant_off_channel_25 = reportedProperties?.['instant_off_channel_25'] ?? null;
        if (desiredProperties?.['instant_off_channel_25'] !== undefined) {
            pendingModel['instant_off_channel_25'] = +desiredProperties['instant_off_channel_25'] !== model['instant_off_channel_25'];
        }

        //------------------------------------------------------------------------------------------------
        // Instant-Off Delay
        //------------------------------------------------------------------------------------------------
        model.instant_off_delay_26 = reportedProperties?.['instant_off_delay_26'] ?? 200;
        if (desiredProperties?.['instant_off_delay_26'] !== undefined) {
            pendingModel['instant_off_delay_26'] = +desiredProperties['instant_off_delay_26'] !== model['instant_off_delay_26'];
        }

        // Return values to populate the reactive form
        return {
            interruptionModel: model,
            interruptionPendingModel: pendingModel
        };
    }

    // -----------------------------------------------------------------
    // Builds the payload to update the device twin
    //  -----------------------------------------------------------------
    public buildJsonPayload(originalForm: FormGroup, updatedForm: FormGroup, unit: Unit, instanceId: number): any {


        let index = '0';
        if (unit.brand.unitStyleId == UnitStyleEnum.Dome  || unit.brand.unitStyleId == UnitStyleEnum.IOModule)
        {
            index = `${instanceId}000`;
        }

        // Initialize the JSON request (with calculated values)
        const request = {
            'ai_interrupt_31040': {}
        };
        request['ai_interrupt_31040'][index] = {};

        // Iterate through all the updated controls and configure the key and value
        if (updatedForm.controls['interruption_mode_0']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['interruption_mode_0'] = +updatedForm.controls['interruption_mode_0'].value;
        }
        if (updatedForm.controls['on_time_1']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['on_time_1'] = (+updatedForm.controls['on_time_1'].value * 10);
        }
        if (updatedForm.controls['off_time_2']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['off_time_2'] = (+updatedForm.controls['off_time_2'].value * 10);
        }
        if (updatedForm.controls['output_mode_3']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['output_mode_3'] = +updatedForm.controls['output_mode_3'].value;
        }
        if (updatedForm.controls['sync_mode_4']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['sync_mode_4'] = +updatedForm.controls['sync_mode_4'].value;
        }
        if (updatedForm.controls['offset_15']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['offset_15'] = +updatedForm.controls['offset_15'].value;
        }
        const resourceOffset: number = updatedForm.controls['offset_15']?.value ?? originalForm.controls['offset_15']?.value;
        if (updatedForm.controls['start_time_8']?.value !== undefined || updatedForm.controls['offset_15']?.value !== undefined) {
            if (!!updatedForm.controls['start_time_8']?.value) {
                request['ai_interrupt_31040'][index]['start_time_8'] = this.convertTimeToDeciseconds(updatedForm.controls['start_time_8'].value, resourceOffset);
            }
            else {
                request['ai_interrupt_31040'][index]['start_time_8'] = this.convertTimeToDeciseconds(originalForm.controls['start_time_8'].value, resourceOffset);
            }
            
        }
        if (updatedForm.controls['stop_time_9']?.value !== undefined || updatedForm.controls['offset_15']?.value !== undefined) {
            if (!!updatedForm.controls['stop_time_9']?.value) {
                request['ai_interrupt_31040'][index]['stop_time_9'] = this.convertTimeToDeciseconds(updatedForm.controls['stop_time_9'].value, resourceOffset);
            }
            else {
                request['ai_interrupt_31040'][index]['stop_time_9'] = this.convertTimeToDeciseconds(originalForm.controls['stop_time_9'].value, resourceOffset);
            }
        }
        if (updatedForm.controls['start_date_10']?.value !== undefined || updatedForm.controls['interruption_mode_0']?.value !== undefined || 
            updatedForm.controls['start_time_8']?.value !== undefined || updatedForm.controls['offset_15']?.value !== undefined) {
            const interruptionMode = updatedForm.controls['interruption_mode_0']?.value ?? updatedForm.controls['interruption_mode_0']?.value;
            request['ai_interrupt_31040'][index]['start_date_10'] = this.updateStartStopDateTime(originalForm, updatedForm, 'start_date_10', 'start_time_8', interruptionMode, true);
        }
        if (updatedForm.controls['stop_date_11']?.value !== undefined || updatedForm.controls['interruption_mode_0']?.value !== undefined ||
            updatedForm.controls['stop_time_9']?.value !== undefined || updatedForm.controls['offset_15']?.value !== undefined) {
            const interruptionMode = updatedForm.controls['interruption_mode_0']?.value ?? updatedForm.controls['interruption_mode_0']?.value;
            request['ai_interrupt_31040'][index]['stop_date_11'] = this.updateStartStopDateTime(originalForm, updatedForm, 'stop_date_11', 'stop_time_9', interruptionMode, false);
        }
        if (updatedForm.controls['upper_threshold_20']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['lower_threshold_19'] = +updatedForm.controls['upper_threshold_20'].value * 1000;
            request['ai_interrupt_31040'][index]['upper_threshold_20'] = +updatedForm.controls['upper_threshold_20'].value * 1000;
        }
        if (updatedForm.controls['verification_enabled_23']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['verification_enabled_23'] = updatedForm.controls['verification_enabled_23'].value;
        }
        if (updatedForm.controls['number_of_units_5']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['number_of_units_5'] = +updatedForm.controls['number_of_units_5'].value;
        }
        if (updatedForm.controls['unit_number_6']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['unit_number_6'] = +updatedForm.controls['unit_number_6'].value;
        }
        if (updatedForm.controls['delay_between_units_7']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['delay_between_units_7'] = (+updatedForm.controls['delay_between_units_7'].value * 10);
        }
        if (updatedForm.controls['instant_off_enabled_24']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['instant_off_enabled_24'] = updatedForm.controls['instant_off_enabled_24'].value;
        }
        if (updatedForm.controls['instant_off_channel_25']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['instant_off_channel_25'] = +updatedForm.controls['instant_off_channel_25'].value;
        }
        if (updatedForm.controls['instant_off_delay_26']?.value !== undefined) {
            request['ai_interrupt_31040'][index]['instant_off_delay_26'] = +updatedForm.controls['instant_off_delay_26'].value;
        }

        // Validate start and stop times after conversion
        const interruptionMode = updatedForm?.controls?.interruption_mode_0?.value ?? originalForm?.controls?.interruption_mode_0?.value ?? 0;
        if (interruptionMode === InterruptionModeEnum.startStop) {
            const startTimeHourMinuteTimeString: string = updatedForm?.controls?.start_time_8?.value ?? originalForm?.controls?.start_time_8?.value;
            const stopTimeHourMinuteTimeString: string = updatedForm?.controls?.stop_time_9?.value ?? originalForm?.controls?.stop_time_9?.value;
            const startTimeDeciseconds = this.convertTimeToDeciseconds(startTimeHourMinuteTimeString, resourceOffset);
            let stopTimeDeciseconds = this.convertTimeToDeciseconds(stopTimeHourMinuteTimeString, resourceOffset);
            if (stopTimeDeciseconds < startTimeDeciseconds) {
                request['ai_interrupt_31040'][index]['stop_time_9'] = stopTimeDeciseconds += 864000;
            }
        }

        // return request
        return request;
    }

    // -----------------------------------------------------------------
    // Builds the reactive form which the user fills out
    //  -----------------------------------------------------------------
    public recreateForm(unit: Unit, form: FormGroup, formBuilder: FormBuilder, instanceId: number): FormGroup {
        const newForm = formBuilder.group({});
        newForm.addControl('interruption_mode_0', new FormControl(form.controls.interruption_mode_0.value, Validators.required));
        if (form.controls.interruption_mode_0.value === InterruptionModeEnum.interruptionOff || form.controls.interruption_mode_0.value === InterruptionModeEnum.cathodicProtectionOff) {
            const value = form.controls.output_mode_3.value ?? this.getInterruptionFormControls(unit, instanceId).interruptionModel.output_mode_3;
            newForm.addControl('output_mode_3', new FormControl(value, Validators.required));
        }
        if (form.controls.interruption_mode_0.value === InterruptionModeEnum.startStop) {
            const keys = ['on_time_1', 'off_time_2', 'output_mode_3', 'sync_mode_4', 'start_time_8', 'stop_time_9', 'start_date_10', 'stop_date_11', 'offset_15'];
            _.forEach(keys, (key) => {
                newForm.addControl(key, new FormControl(form.controls[key]?.value ?? this.getInterruptionFormControls(unit, instanceId).interruptionModel[key], Validators.required));
            });
        }
        if (form.controls.interruption_mode_0.value === InterruptionModeEnum.continuous) {
            const keys = ['on_time_1', 'off_time_2', 'output_mode_3', 'sync_mode_4'];
            _.forEach(keys, (key) => {
                newForm.addControl(key, new FormControl(form.controls[key]?.value ?? this.getInterruptionFormControls(unit, instanceId).interruptionModel[key], Validators.required));
            });
        }

        // Set instant-off controls
        const instantOffChannel = 'instant_off_channel_25';
        newForm.addControl(instantOffChannel, new FormControl(form.get[instantOffChannel]?.value ?? this.getInterruptionFormControls(unit, instanceId).interruptionModel[instantOffChannel], Validators.required));
        const instantOffEnabled = 'instant_off_enabled_24';
        newForm.addControl(instantOffEnabled, new FormControl(form.get[instantOffEnabled]?.value ?? this.getInterruptionFormControls(unit, instanceId).interruptionModel[instantOffEnabled], Validators.required));
        const instantOffDelay = 'instant_off_delay_26';
        newForm.addControl(instantOffDelay, new FormControl(form.get[instantOffDelay]?.value ?? this.getInterruptionFormControls(unit, instanceId).interruptionModel[instantOffDelay], Validators.required));

        if (this.hasInterruptionVerification(unit))
        {
            // Set interruption verification controls
            const verificationKey = 'verification_enabled_23';
            newForm.addControl(verificationKey, new FormControl(form.get[verificationKey]?.value ?? this.getInterruptionFormControls(unit, instanceId).interruptionModel[verificationKey], Validators.required));
            const thresholdKey = 'upper_threshold_20';
            newForm.addControl(thresholdKey, new FormControl(form.get[thresholdKey]?.value ?? this.getInterruptionFormControls(unit, instanceId).interruptionModel[thresholdKey], [Validators.required, Validators.min(1), Validators.max(10000)]));
            newForm.addControl('has_interruption', new FormControl(true, [Validators.required]));
        }

        if (this.hasInterferenceSupport(unit))
        {
            // Set interference support controls
            const numberOfUnitsKey = 'number_of_units_5';
            newForm.addControl(numberOfUnitsKey, new FormControl(form.get[numberOfUnitsKey]?.value ?? this.getInterruptionFormControls(unit, instanceId).interruptionModel[numberOfUnitsKey], [Validators.required, Validators.min(1), Validators.max(99)]));
            const unitNumberKey = 'unit_number_6';
            newForm.addControl(unitNumberKey, new FormControl(form.get[unitNumberKey]?.value ?? this.getInterruptionFormControls(unit, instanceId).interruptionModel[unitNumberKey], [Validators.required, Validators.min(1), Validators.max(99)]));
            const delayBetweenUnitsKey = 'delay_between_units_7';
            newForm.addControl(delayBetweenUnitsKey, new FormControl(form.get[delayBetweenUnitsKey]?.value ?? this.getInterruptionFormControls(unit, instanceId).interruptionModel[delayBetweenUnitsKey], [Validators.required, Validators.min(1), Validators.max(9999)]));
            newForm.addControl('has_interference', new FormControl(true, [Validators.required]));
        }
        // Return form
        newForm.controls['interruption_mode_0'].markAsDirty();
        return newForm;
    }

    hasInterruptionVerification(unit: Unit): boolean {
        return (unit.brandId != 72 && unit.brandId != 73);
    }

    hasInterferenceSupport(unit: Unit): boolean {
        return (unit.brandId != 72 && unit.brandId != 73);
    }

    // -----------------------------------------------------------------------------------------------------
    // Get all Timezones
    // -----------------------------------------------------------------------------------------------------
    async getAllTimeZonesAsync(): Promise<TimeZone[]> {
        const request = await this._apiService.getAsync<Response<TimeZone[]>>(`${environment.bullhornApiUrl}/TimeZone/GetTimeZonesAsync`).then();
        return request.result;
    }

    // -----------------------------------------------------------------------------------------------------
    // Calculates the UTC offset based on the selected time zone
    // -----------------------------------------------------------------------------------------------------
    public calculateUtcOffset(timeZone: TimeZone, isDaylightSavingsObserved: boolean): number {
        // If daylight savings time is not observed, return the results
        if (!isDaylightSavingsObserved) {
            return timeZone.stdoffset / 60;
        }

        // Get date by parts
        const utcDate = moment.utc(Date());
        const sdtDate = this.getDateByParts(utcDate.year(), timeZone.stdmonth, timeZone.stdweek, timeZone.stddayOfWeek, timeZone.stdhour).add(timeZone.stdoffset * (-1));
        const dstDate = this.getDateByParts(utcDate.year(), timeZone.dstmonth, timeZone.dstweek, timeZone.dstdayOfWeek, timeZone.dsthour).add(timeZone.dstoffset * (-1));

        // Check if std is earlier than dst
        let result = timeZone.stdoffset;
        if (sdtDate.isBefore(dstDate)) {
            if (utcDate.isSameOrAfter(sdtDate) && utcDate.isSameOrBefore(dstDate)) {
                result = timeZone.dstoffset;
            } else {
                result = timeZone.stdoffset;
            }
        } else {
            if (utcDate.isSameOrAfter(dstDate) && utcDate.isSameOrBefore(sdtDate)) {
                result = timeZone.stdoffset;
            } else {
                result = timeZone.dstoffset;
            }
        }
        return result / 60;
    }

    // -----------------------------------------------------------------------------------------------------
    // Calculates the UTC offset based on the selected time zone
    // -----------------------------------------------------------------------------------------------------
    public getDateByParts(year: number, month: number, week: number, dayOfWeek: number, hour: number): moment.Moment {
        // Get the first of the month
        let firstOfMonth = moment(`${year}-${month}-1`);

        // Check if the week is equal to 5
        if (week === 5) {
            firstOfMonth = firstOfMonth.add(1);
        }

        // initial constants/variables
        const dow = firstOfMonth.day() + 1;
        let results = moment(Date());

        // check if first day of week is less than day of week input
        if (dow > dayOfWeek) {
            results = firstOfMonth.add(7 + (dayOfWeek + dow));
        } else {
            results = firstOfMonth.add(dayOfWeek - dow);
        }

        // Calculate when week is less than 5
        if (week < 5) {
            results = results.add(7 * (week - 1));
        } else {
            results = results.add(-7);
        }

        // Return results
        results = results.hour(hour);
        return results;
    }

    // -----------------------------------------------------------------------------------------------------
    // Converts the time string to deciseconds starting from midnight UTC
    // -----------------------------------------------------------------------------------------------------
    public convertTimeToDeciseconds(hourMinuteTimeString: string, resourceOffset: number): number {
        // Using moment-timezone to get and set local time zone
        const timezones = momentTZ.tz.names();
        // Find a timezone that matches the given offset
        const timezone = timezones.find(tz => momentTZ.tz(tz).utcOffset() === resourceOffset * 60);
        const timeInOriginalZone = momentTZ.tz(hourMinuteTimeString, 'HH:mm A', timezone);
        const militaryTime = moment(timeInOriginalZone, ['h:mm A']).utc().format('HH:mm');
        const timespan = militaryTime.split(':');
        return ((+timespan[0] * 3600) + (+timespan[1] * 60)) * 10;
    }

    // -----------------------------------------------------------------------------------------------------
    // Updates the start/end date's time.
    // -----------------------------------------------------------------------------------------------------
    private updateStartStopDateTime(originalForm: FormGroup, updatedForm: FormGroup, resourceDate: string, resourceTime: string, interruptionMode: InterruptionModeEnum, isStart: boolean): any {
        // Get the date, time, UTC
        const date = moment(updatedForm.controls[resourceDate]?.value ?? originalForm.controls[resourceDate]?.value ?? new Date()).format('YYYY-MM-DD');
        const time = updatedForm.controls[resourceTime]?.value ?? originalForm.controls[resourceTime]?.value ?? '12:00 AM';
        const utcOffset = updatedForm.controls['offset_15']?.value ?? originalForm.controls['offset_15']?.value ?? 0;

        // Set the new time
        let startTimeResult = new Date();
        startTimeResult.setTime(moment((`${date} ${time}`), "YYYY/MM/DD h:mm a").toDate().getTime() + (utcOffset * -1 * 60 * 60 * 1000));

        // Update the hours and minutes
        let dateString = moment(new Date(startTimeResult.toDateString())).format(`YYYY-MM-DDT${isStart ? '00:00:00' : '23:59:59'}`);
        return dateString + 'Z';
    }

    // -----------------------------------------------------------------------------------------------------
    //  convert Deciseconds To Time String: HH:MM
    // -----------------------------------------------------------------------------------------------------
    convertDecisecondsToTimeString(date: Date, seconds: number) {
        const utcSeconds = ((seconds ?? 0) / 10);
        const hourMin = moment.utc(utcSeconds * 1000).format('HH:mm');
        return hourMin;
    }

    // -----------------------------------------------------------------------------------------------------
    //  convert the desired properties start or stop date to local time zone using the device offset
    // -----------------------------------------------------------------------------------------------------
    convertUTCToLocalTime(resourceDate: string, resourceTime: string, resourceOffset: number): Date {
        // Using moment-timezone to get and set local time zone
        const timezones = momentTZ.tz.names();

        // Find a timezone that matches the given offset
        const timezone = timezones.find(tz => momentTZ.tz(tz).utcOffset() === resourceOffset * 60);

        // Current UTC Date from Device Twin with the time example "2024-06-03 12:00:00";
        let dateUTC = resourceDate + ' ' + resourceTime; 

        // Get local timezone with UTC Date and timezone of the device
        const utcDateTime = momentTZ.utc(dateUTC).tz(timezone).format('YYYY-MM-DD HH:mm:ss');

        // Use moment to cast it to Date and return
        const localDateTime = moment(`${utcDateTime}`, 'YYYY-MM-DDTHH:mm');
        const returnLocalDateTime = localDateTime.utc().toDate();
    
        return returnLocalDateTime;
    }
}
