import {Route, RouterModule} from '@angular/router';
import {AuthGuard} from 'app/core/guards/auth.guard';
import {NoAuthGuard} from 'app/core/guards/no-auth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {InitialDataResolver} from 'app/app.resolvers';
import {NgModule} from '@angular/core';
import {CustomPreloadingStrategyService} from './core/services/custom-preloading.service';

// @formatter:off
// tslint:disable:max-line-length
export const routes: Route[] = [

    // Redirect empty path to '/reporting'
    {path: '', pathMatch: 'full', redirectTo: 'reporting'},

    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'reporting'},

    // Routes for guests
    {
        path: 'sign-in',
        canActivate: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
    },

    // Routes for authenticated users
    {
        path: 'readings',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () => import('app/modules/admin/readings-and-settings/readings/readings.module').then(m => m.ReadingsModule)
    },
    {
        path: 'channel-settings',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () => import('app/modules/admin/readings-and-settings/channel-settings/channel-settings.module').then(m => m.ChannelSettingsModule)
    },
    {
        path: 'reporting',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver
        },
        loadChildren: () => import('app/modules/admin/reporting/reporting.module').then(m => m.ReportingModule)
    },
    {
        path: 'interruption',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver
        },
        loadChildren: () => import('app/modules/admin/interruption/interruption.module').then(m => m.InterruptionModule)
    },
    {
        path: 'help-center',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () => import('app/layout/common/help-center/help-center.module').then(m => m.HelpCenterModule)
    },
    {path: '**', redirectTo: 'reporting'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategyService})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
