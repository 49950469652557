import {NgModule, Optional, SkipSelf} from '@angular/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {CoreConfirmationModule} from '@core/services/confirmation';
import {CoreMediaWatcherModule} from '@core/services/media-watcher/media-watcher.module';
import {CoreSplashScreenModule} from '@core/services/splash-screen/splash-screen.module';
import {CoreTailwindConfigModule} from '@core/services/tailwind/tailwind.module';
import {CoreUtilsModule} from '@core/services/utils/utils.module';

@NgModule({
    imports: [
        CoreConfirmationModule,
        CoreMediaWatcherModule,
        CoreSplashScreenModule,
        CoreTailwindConfigModule,
        CoreUtilsModule
    ],
    providers: [
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            }
        }
    ]
})
export class CoreAppModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: CoreAppModule) {
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only!');
        }
    }
}
