import {NgModule} from '@angular/core';
import {ProperCasePipe} from './proper-case.pipe';
import {AlphanumericSpacePipe} from './alphanumeric-space.pipe';
@NgModule({
    declarations: [
        ProperCasePipe,
        AlphanumericSpacePipe
    ],
    exports: [
        ProperCasePipe,
        AlphanumericSpacePipe
    ]
})
export class CorePipesModule {
}
