import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreDrawerComponent } from '@core/components/drawer/drawer.component';

@NgModule({
    declarations: [
        CoreDrawerComponent
    ],
    imports     : [
        CommonModule
    ],
    exports     : [
        CoreDrawerComponent
    ]
})
export class CoreDrawerModule
{
}
