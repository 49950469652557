import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CoreVerticalNavigationComponent} from '@core/components/navigation/vertical/vertical.component';
import {CoreNavigationService} from '@core/components/navigation/navigation.service';
import {CoreNavigationItem} from '@core/components/navigation/navigation.types';

@Component({
    selector: 'core-vertical-navigation-spacer-item',
    templateUrl: './spacer.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreVerticalNavigationSpacerItemComponent implements OnInit, OnDestroy {
    @Input() item: CoreNavigationItem;
    @Input() name: string;

    private _coreVerticalNavigationComponent: CoreVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _coreNavigationService: CoreNavigationService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the parent navigation component
        this._coreVerticalNavigationComponent = this._coreNavigationService.getComponent(this.name);

        // Subscribe to onRefreshed on the navigation component
        this._coreVerticalNavigationComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
