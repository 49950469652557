export enum AnalogScratchpadEnum {
    ac_input_type = 1,
    dc_input_type,
    shunt_enabled,
    shunt_volt_input,
    shunt_amps_scaled,
    scale_max_from, // Input Min
    scale_max_to, // Input Max
    scale_min_from, // Scale Min
    scale_min_to// Scale Max
}

//-----------------------------------------------
// Constants for volts and millivolts multiplier
//-----------------------------------------------
export enum MultiplierConstants {
    milliVoltsMultiplier = 1000,
    voltsMultiplier = 1000000,
}
