import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {AuthService} from "@ai/ngx-concentric";

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        AuthService
    ]
})
export class AuthModule {
}
