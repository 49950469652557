<!------------------------------------------------------------>
<!-- Main Content -->
<!------------------------------------------------------------>
<div class="flex flex-col flex-auto min-w-0">

    <!------------------------------------------------------------>
    <!-- Breadcrumb -->
    <!------------------------------------------------------------>
    <breadcrumb *ngIf="showBreadcrumb === 'true'" breadcrumbTitle="Reporting"
                description="Configure the frequency of measurements & transmissions"
                [unit]="unit"></breadcrumb>

    <!------------------------------------------------------------>
    <!-- Form -->
    <!------------------------------------------------------------>
    <div *ngIf="unit && reportingFormResponse" class="flex flex-col flex-auto items-center p-6 sm:p-10">
        <div class="flex flex-col w-full max-w-4xl">
            <div style="background-color:{{cardColor}}!important" class="pt-4 pl-6 pr-6 pb-3 shadow rounded-2xl">

                <!------------------------------------------------------------>
                <!-- Form Title -->
                <!------------------------------------------------------------>
                <div *ngIf="showCardTitle === 'true'" class="text-3xl font-bold pb-4">
                    <div class="bg-gray-100 px-4 py-3 rounded relative">
                        {{unit.clientUnitName}}
                    </div>
                </div>

                <!------------------------------------------------------------>
                <!-- Interval -->
                <!------------------------------------------------------------>
                <form [formGroup]="form" id="interval-form" class="flex flex-col gt-sm:flex-row">
                    <div class="flex flex-col">
                        <!------------------------------------------------------------>
                        <!-- Reporting Mode -->
                        <!------------------------------------------------------------>
                        <div id="measurements-container">
                            <div class="flex flex-col pt-2">
                                <div class="flex flex-row">
                                    <mat-label class="font-semibold">Reporting Mode</mat-label>
                                    <pending-indicator
                                        [show]="reportingFormResponse.ReportingPendingModel.schedule_mode_1"></pending-indicator>
                                </div>
                                <mat-form-field class="core-mat-dense flex-auto">
                                    <mat-select id="reporting-mode-select-box" formControlName="scheduleMode"
                                                placeholder="Choose a reporting mode"
                                                (selectionChange)="subscribeToFormChanges()">
                                        <mat-option [value]="scheduleMode.value"
                                                    *ngFor="let scheduleMode of scheduleModes">
                                            <span>{{scheduleMode.key}}</span>
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon class="icon-size-5"
                                              matPrefix
                                              svgIcon="{{form.controls.scheduleMode.value === scheduleMode.repeat ? 'mat_solid:update':'mat_solid:today'}}">
                                    </mat-icon>
                                    <mat-hint>Set the frequency of reporting</mat-hint>
                                    <mat-error>You must select a reporting mode</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <!------------------------------------------------------------>
                        <!-- Start Date -->
                        <!------------------------------------------------------------>
                        <div *ngIf="form.controls.scheduleMode.value !== scheduleMode.off" id="start-date-container">
                            <div class="flex flex-col">
                                <div class="flex flex-row">
                                    <mat-label class="font-semibold">Start Date</mat-label>
                                    <pending-indicator
                                        [show]="reportingFormResponse.ReportingPendingModel.startDay"></pending-indicator>
                                </div>
                                <mat-form-field class="flex-auto">
                                    <input id="start-date-picker" (focus)="picker.open()" matInput
                                           type='text'
                                           formControlName="startDay"
                                           [placeholder]="'MM/DD/YYYY'" [matDatepicker]="picker">
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'mat_solid:today'"></mat-icon>
                                    <mat-error>You must provide a start date</mat-error>
                                    <mat-hint>Set the date reporting begins</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>

                        <!------------------------------------------------------------>
                        <!-- Time of Day -->
                        <!------------------------------------------------------------>
                        <div *ngIf="form.controls.scheduleMode.value !== scheduleMode.off" id="time-of-day-container">
                            <div class="flex flex-col">
                                <div class="flex flex-row">
                                    <mat-label class="font-semibold">Start Time</mat-label>
                                    <pending-indicator
                                        [show]="reportingFormResponse.ReportingPendingModel.start_time_2"></pending-indicator>
                                </div>
                                <mat-form-field class="flex-auto">
                                    <input id="time-of-day-input" readonly type='text' matInput
                                           formControlName="startTime" [ngxTimepicker]="picker">
                                    <ngx-material-timepicker #picker></ngx-material-timepicker>
                                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'mat_solid:alarm'"></mat-icon>
                                    <mat-error>You must provide a time of day</mat-error>
                                    <mat-hint>Set the time of day reporting begins</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>

                        <!------------------------------------------------------------>
                        <!-- Reporting Interval - When Reporting Mode = Repeat -->
                        <!------------------------------------------------------------>
                        <div
                            *ngIf="form.controls.scheduleMode.value !== scheduleMode.off && form.controls.scheduleMode.value === scheduleMode.repeat"
                            id="reporting-interval-container">
                            <div class="flex flex-col pt-2">
                                <div class="flex flex-row">
                                    <mat-label class="font-semibold {{reportingIntervals.length === 0 ? 'text-gray-400':''}}">Reporting Interval</mat-label>
                                    <pending-indicator
                                        [show]="reportingFormResponse.ReportingPendingModel.repeat_interval_3"></pending-indicator>
                                </div>
                                <mat-form-field class="core-mat-dense flex-auto">
                                    <mat-select id="reporting-interval-select-box" formControlName="reportingInterval"
                                                placeholder="Choose a reporting interval">
                                        <mat-option [value]="reportingInterval.value"
                                                    *ngFor="let reportingInterval of reportingIntervals">
                                            <span>{{reportingInterval.key}}</span>
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon class="icon-size-5" matPrefix
                                              [svgIcon]="'mat_solid:restart_alt'"></mat-icon>
                                    <mat-hint *ngIf="this.reportingIntervals.length > 0">Set the interval to schedule a report</mat-hint>
                                    <mat-error>You must select a reporting interval</mat-error>
                                </mat-form-field>
                                <div class="text-red-600 text-sm -my-4" *ngIf="this.reportingIntervals.length === 0">There are no reporting intervals to select from because the unit's bill plan has not been set or is inactive</div>
                            </div>
                        </div>

                        <!------------------------------------------------------------>
                        <!-- Reporting Interval - When Reporting Mode = Day of Month -->
                        <!------------------------------------------------------------>
                        <div *ngIf="form.controls.scheduleMode.value !== scheduleMode.off
                            && form.controls.scheduleMode.value === scheduleMode.dayOfMonth"
                             id="reporting-day-of-month-container">
                            <div class="flex flex-col pt-2">
                                <div class="flex flex-row">
                                    <mat-label class="font-semibold">Days of the month</mat-label>
                                    <pending-indicator
                                        [show]="reportingFormResponse.ReportingPendingModel.day_of_month_4"></pending-indicator>
                                </div>
                                <mat-form-field class="core-mat-dense flex-auto">
                                    <mat-select id="day-of-month-select-box" formControlName="daysOfTheMonth" multiple
                                                placeholder="Choose up to 4 days of the month">
                                        <mat-option *ngFor="let day of daysOfTheMonth" [value]="day.value"
                                                    [disabled]="isOptionDisabled(day.value)">
                                            {{day.key}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon class="icon-size-5" matPrefix
                                              [svgIcon]="'mat_solid:date_range'"></mat-icon>
                                    <mat-hint>Set the days of the month to schedule a report</mat-hint>
                                    <mat-error>You must choose at least 1 day of the month</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>

                <!------------------------------------------------------------>
                <!-- Actions -->
                <!------------------------------------------------------------>
                <div class="flex items-center justify-end">
                    <button id="readings-save-button" type="button" class="ml-2" mat-button [color]="'primary'" type="button"
                            (click)="saveSettingsAsync()"><span class="uppercase">Save</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!------------------------------------------------------------>
<!-- Search bar - Shown when unit is not selected -->
<!------------------------------------------------------------>
<unit-search-form *ngIf="showUnitSearch === 'true'" showUnitSearch="true" id="search-prompt"></unit-search-form>
