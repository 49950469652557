import {EventEmitter, Injectable, Output} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import {ActivatedRoute} from '@angular/router';
import {UnitService} from './unit.service';
import {environment} from '../../../environments/environment';
import {Observable, ReplaySubject} from 'rxjs';
import {Unit} from '../models/unit.model';

interface ClientPayload {
    Type: string;
    Body: any;
}

@Injectable()
/// <summary>
/// The ClientHubService
/// </summary>
export class BwctHubService {
    @Output() connectionEstablished = new EventEmitter<boolean>();
    private _connectionIsEstablished = false;
    private _hubConnection: signalR.HubConnection;
    private _unit: ReplaySubject<Unit> = new ReplaySubject<Unit>(1);

    // -----------------------------------------------------------------------------------------------------
    // setter for the unit settings
    // -----------------------------------------------------------------------------------------------------
    set unit(value: Unit) {
        // Store the value
        this._unit.next(value);
    }

    // -----------------------------------------------------------------------------------------------------
    // Getter for the unit settings
    // -----------------------------------------------------------------------------------------------------
    get unit$(): Observable<Unit> {
        return this._unit.asObservable();
    }

    /// <summary>
    /// Constructs the Client Service
    /// </summary>
    constructor(private _route: ActivatedRoute,
                private _unitService: UnitService) {
        this.createConnection();
        this.registerOnServerEvents();
        this.startConnection();
    }

    /// <summary>
    /// Creates the SignalR Connection
    /// </summary>
    createConnection(): void {
        this._hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.clientKernelUrl}/ClientHub`)
            .withAutomaticReconnect()
            .build();
    }

    /// <summary>
    /// Starts the SignalR Connection
    /// </summary>
    startConnection(): void {
        const that = this;
        this._hubConnection
            .start()
            .then(() => {
                this._connectionIsEstablished = true;
                console.log('SignalR: Hub connection started');
                this.connectionEstablished.emit(true);
            })
            .catch(() => {
                console.log('Error while establishing SignalR Hub connection: retrying...');
                setTimeout(() => {
                    that.startConnection();
                }, 5000);
            });
    }

    /// <summary>
    /// Registers server events and broadcasts message
    /// </summary>
    registerOnServerEvents(): void {
        /// <summary>
        // Adds the user to the group when connected to SignalR
        /// <summary>
        this._hubConnection.on('OnConnectedAsync', (response: string) => {
            this._hubConnection.invoke('AddUserToGroup', 'BWCT-UpdateUnitSettingsGroup')
                .then(() => {
                    console.log('SignalR: Hub connection completed');
                }).catch(err => console.error(err.toString()));
        });

        /// <summary>
        // Handles messages from the client kernel (ck) after the ck invokes UpdateUnitSettingsAsync
        /// <summary>
        this._hubConnection.on('UpdateUnitSettingsAsync', async (data: any) => {
            try {
                // Check if the unit is saved in storage
                if (localStorage.getItem('selectedUnitId') === null) {
                    return;
                }

                // Cast the payload from the client kernel to Unit type
                const payload: ClientPayload = JSON.parse(data);
                const updatedUnitId = payload.Body as number;
                const unitId = +localStorage.getItem('selectedUnitId');

                // Send update
                if (updatedUnitId === unitId) {
                    const unit = await this._unitService.getUnitByIdAsync(updatedUnitId).then();
                    this._unit.next(unit);
                }
            } catch (e) {
                console.log(`Bwct Hub Service: Failed to emit UpdateUnitSettingsAsync event: ${e}`);
            }
        });
    }
}
