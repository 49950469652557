import {Injectable} from '@angular/core';
import {cloneDeep} from 'lodash-es';
import {CoreInternalApiService} from '@core/lib/internal-api';
import {faqCategoriesData, faqsData} from './data';

@Injectable({
    providedIn: 'root'
})
export class HelpCenterInternalApi {
    private _faqCategories: any[] = faqCategoriesData;
    private _faqs: any[] = faqsData;

    constructor(private _internalApiService: CoreInternalApiService) {
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // Get faqs
    // -----------------------------------------------------------------------------------------------------
    registerHandlers(): void {
        this._internalApiService
            .onGet('api/common/help-center/faqs')
            .reply(({request}) => {

                // Get the category slug
                const slug = request.params.get('slug');

                // Prepare the results
                const results = [];

                // Get FAQs
                const faqs = cloneDeep(this._faqs);

                // Get FAQ Categories
                const categories = cloneDeep(this._faqCategories);

                // If slug is not provided...
                if (!slug) {
                    // Go through each category and set the results
                    categories.forEach((category) => {

                        results.push(
                            {
                                ...category,
                                faqs: faqs.filter(faq => faq.categoryId === category.id)
                            }
                        );
                    });
                }
                // Otherwise...
                else {
                    // Find the category by the slug
                    const category = categories.find(item => item.slug === slug);

                    // Set the results
                    results.push(
                        {
                            ...category,
                            faqs: faqs.filter(faq => faq.categoryId === category.id)
                        }
                    );
                }

                // Return the response
                return [200, results];
            });
    }
}
