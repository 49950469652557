<!------------------------------------------------------------>
<!-- Main Content -->
<!------------------------------------------------------------>
<div class="flex flex-col flex-auto min-w-0">

    <!------------------------------------------------------------>
    <!-- Breadcrumb -->
    <!------------------------------------------------------------>
    <breadcrumb *ngIf="showBreadcrumb === 'true'" breadcrumbTitle="Readings"
                description="Update your RMU's channel settings"
                [unit]="unit" [instanceId]="instanceId" [showInstanceId]="showInstanceId">
    </breadcrumb>

    <!------------------------------------------------------------>
    <!-- Form -->
    <!------------------------------------------------------------>
    <div *ngIf="unit" class="flex flex-col flex-auto items-center p-6 sm:p-10">
        <div class="flex flex-col w-full max-w-4xl">
            <div style="background-color:{{cardColor}}!important"
                 class="pt-4 pl-6 pr-6 pb-3 shadow rounded-2xl bg-white">

                <!------------------------------------------------------------>
                <!-- Form Title -->
                <!------------------------------------------------------------>
                <div *ngIf="showCardTitle === 'true'" class="text-3xl font-bold">
                    <div class="bg-gray-100 px-4 py-3 rounded relative">
                        {{unit.clientUnitName}}<span *ngIf="showInstanceId == true">: I/O {{instanceId}}</span>
                    </div>
                </div>

                <!------------------------------------------------------------>
                <!-- Analog Channels -->
                <!------------------------------------------------------------>
                <div class="mt-2 text-2xl font-bold" *ngIf="_analogChannels?.length > 0">
                    <div class="bullhorn-blue px-4 py-1 rounded relative text-white">
                        Analog Channels
                    </div>
                </div>

                <div class="flex flex-col mt-2 divide-y border-t border-b" *ngIf="_analogChannels?.length > 0">
                    <!--------------------------------------------------------------->
                    <!-- Analog AC/DC Channels -->
                    <!--------------------------------------------------------------->
                    <ng-container *ngFor="let channel of _analogChannels">
                        <readings-toggle-button
                            *ngFor="let electricalCurrent of ['dc', 'ac']"
                            [rowColor]="electricalCurrent === 'dc' ? 'bg-gray-100': ''"
                            [toggleButtonId]="'analog-channel-button-' + channel.channelId"
                            [toggleButtonTitle]="channel.channelName"
                            [toggleButtonSubTitle]="electricalCurrent | Readings"
                            [toggleButtonBackgroundColor]="getDataPointById(electricalCurrent === 'dc' ? channel.analogDCDataPointId : channel.analogACDataPointId)?.enabled ? 'bg-green-600' : 'bg-gray-400'"
                            [dataPointName]="getDataPointById(electricalCurrent === 'dc' ? channel.analogDCDataPointId : channel.analogACDataPointId)?.dataPointName"
                            [datapointTextColor]="getDataPointById(electricalCurrent === 'dc' ? channel.analogDCDataPointId : channel.analogACDataPointId)?.enabled ? 'text-black' : 'text-gray-400'"
                            (toggleReading)="toggleChannelReadings(true, electricalCurrent === 'dc' ? channel.analogDCDataPointId : channel.analogACDataPointId)"
                            (toggleSideBar)="toggleChannelSettingsSidebar(true, channel.channelId, electricalCurrent)">
                        </readings-toggle-button>
                    </ng-container>
                </div>

                <!------------------------------------------------------------>
                <!-- Digital Channels -->
                <!------------------------------------------------------------>
                <div *ngIf="_digitalChannels?.length > 0" class="mt-2 text-2xl font-bold">
                    <div class="bullhorn-blue px-4 py-1 rounded relative text-white">
                        Digital Channels
                    </div>
                </div>
                <div class="flex flex-col mt-2 divide-y border-t border-b" *ngIf="_digitalChannels?.length > 0">
                    <!--------------------------------------------------------------->
                    <!-- Container -->
                    <!--------------------------------------------------------------->
                    <ng-container *ngFor="let channel of _digitalChannels; let even = even;">
                        <readings-toggle-button
                            [rowColor]="even ? 'bg-gray-100': ''"
                            [toggleButtonId]="'digital-channel-button-' + channel.channelId"
                            [toggleButtonTitle]="channel.channelName"
                            [toggleButtonBackgroundColor]="getDataPointById(channel.digitalInputDataPointId)?.enabled ? 'bg-green-600' : 'bg-gray-400'"
                            [dataPointName]="getDataPointById(channel.digitalInputDataPointId)?.dataPointName"
                            [datapointTextColor]="getDataPointById(channel.digitalInputDataPointId)?.enabled ? 'text-black' : 'text-gray-400'"
                            (toggleReading)="toggleChannelReadings(false, channel.digitalInputDataPointId)"
                            (toggleSideBar)="toggleChannelSettingsSidebar(false, channel.channelId, 'enabled')">
                        </readings-toggle-button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<!------------------------------------------------------------>
<!-- Search bar - Shown when unit is not selected -->
<!------------------------------------------------------------>
<unit-search-form *ngIf="showUnitSearch === 'true'" id="search-prompt"></unit-search-form>

<!------------------------------------------------------------>
<!-- Settings drawer -->
<!------------------------------------------------------------>
<channel-settings></channel-settings>
