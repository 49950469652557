import {Injectable} from '@angular/core';
import {FormGroup, ValidatorFn} from '@angular/forms';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class FormService {

    // -----------------------------------------------------------------
    // clearValidators, updateValueAndValidity, markAsPristine
    // -----------------------------------------------------------------
    public cleanValidatorsAndUpdateValidity(formControlNames: string[], form: FormGroup): FormGroup {
        _.forEach(formControlNames, (formControlName) => {
            try {
                form.get(formControlName).clearValidators();
                form.get(formControlName).updateValueAndValidity();
                form.get(formControlName).markAsPristine();
            } catch {
            }
        });
        return form;
    }

    // -----------------------------------------------------------------
    // setValidators, updateValueAndValidity
    // -----------------------------------------------------------------
    public setValidatorsAndUpdateValidity(formControlNames: string[], form: FormGroup, validators: ValidatorFn | ValidatorFn[] | null): FormGroup {
        _.forEach(formControlNames, (formControlName) => {
            try {
                form.get(formControlName).setValidators(validators);
                form.get(formControlName).updateValueAndValidity();
                form.get(formControlName).markAsPristine();
            } catch {
            }
        });
        return form;
    }

    // -----------------------------------------------------------------
    // Increments the provided form value
    // -----------------------------------------------------------------
    public increment(form: FormGroup, formControlName: string, maxLimit: number = 10, incrementBy: number = 1, fixedBy: number = 1): FormGroup {
        const currentFormControlValue = +form.controls[formControlName].value;
        if ((currentFormControlValue + incrementBy) > maxLimit) {
            return form;
        }
        const value = +form.get(formControlName).value + incrementBy;
        form.controls[formControlName].setValue(value.toFixed(fixedBy));
        form.controls[formControlName].markAsDirty();
        return form;
    }

    // -----------------------------------------------------------------
    // Decrements the provided form value
    // -----------------------------------------------------------------
    public decrement(form: FormGroup, formControlName: string, minLimit: number = 10, decrementBy: number = 1, fixedBy: number = 1): FormGroup {
        const currentFormControlValue = +form.controls[formControlName].value;
        if ((currentFormControlValue - decrementBy) < minLimit) {
            return form;
        }
        const value = +form.get(formControlName).value - decrementBy;
        form.controls[formControlName].setValue(value.toFixed(fixedBy));
        form.controls[formControlName].markAsDirty();
        return form;
    }

    // -----------------------------------------------------------------
    // converts the control value to a boolean datatype
    // -----------------------------------------------------------------
    public convertControlToBoolean(form: FormGroup, controlName: string): boolean {
        const value = form.controls[controlName]?.value;
        return !!value;
    }
}
