import {Component, Input} from '@angular/core';

@Component({
    selector: 'saving-button',
    templateUrl: './saving-button.component.html'
})
export class SavingButtonComponent {
    @Input() id: string = 'saving-button';
    @Input() isProcessing: boolean = false;
    @Input() text: string;
    @Input() textWhenProcessing: string;
    @Input() color: string = 'primary';
    @Input() disabled: boolean = false;
    @Input() hasErrors: boolean = false;
}
