import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {CoreFullscreenModule} from '@core/components/fullscreen';
import {CoreNavigationModule} from '@core/components/navigation';
import {UserModule} from 'app/layout/common/user/user.module';
import {SharedModule} from 'app/core/shared.module';
import {SimpleComponent} from './simple.component';
import {BreadcrumbModule} from '../../common/breadcrumb/breadcrumb.module';
import {SearchModule} from '../../common/search/search.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {PendingIndicatorModule} from '../../common/pending-indicator/pending-indicator.module';

@NgModule({
    declarations: [
        SimpleComponent
    ],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        CoreFullscreenModule,
        CoreNavigationModule,
        UserModule,
        SharedModule,
        BreadcrumbModule,
        SearchModule,
        MatProgressBarModule,
        PendingIndicatorModule
    ],
    exports: [
        SimpleComponent
    ]
})
export class SimpleLayoutModule {
}
