<!-- Navigation -->
<core-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container coreVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 pb-0">
            <!-- Light version -->
            <img
                class="dark:hidden w-30"
                src="assets/images/logo/bwct_logo.png"
                alt="Logo image">
            <!-- Dark version -->
            <img
                class="hidden dark:flex w-50"
                src="assets/images/logo/bwct_logo_on_dark.png"
                alt="Logo image">
        </div>
    </ng-container>
</core-vertical-navigation>
<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">


    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button type="button"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'mat_outline:menu'"></mat-icon>
        </button>

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- Unit search input -->
            <unit-search-bar [appearance]="isScreenSmall ? 'bar' : 'basic'"></unit-search-bar>

            <!-- Help center icon-->
            <button id="help-outline-button" type="button" mat-icon-button>
                <span class="relative">
                    <mat-icon [routerLink]="['/help-center']" class="w-8 h-8" [svgIcon]="'mat_outline:help_outline'"></mat-icon>
                </span>
            </button>

            <!-- User icon-->
            <user [showAvatar]="false"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">American Innovations &copy; {{currentYear}}</span>
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <span class="pl-1 font-sm secondary-text">v{{version}}</span>
        </div>
    </div>

</div>
