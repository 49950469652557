import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ApiService, AuthService, BroadcastService} from '@ai/ngx-concentric';
import {CookieService} from 'ngx-cookie-service';
import {CoreProgressBarService} from '../../../@core/components/progress-bar/progress-bar.service';

@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
    visible: boolean;
    private _jwtInterceptor: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    // -----------------------------------------------------------------------------------------------------
    // Getter for interceptor

    constructor(private _apiService: ApiService,
                private _authService: AuthService,
                private _broadcastService: BroadcastService,
                private _cookieService: CookieService,
                private _progressBarService: CoreProgressBarService) {
    }

    // -----------------------------------------------------------------------------------------------------
    get jwtInterceptor$(): Observable<boolean> {
        return this._jwtInterceptor.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // intercept all request and append auth header with access token
    // -----------------------------------------------------------------------------------------------------
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this._progressBarService.show();
        const token = this._authService.accessToken;
        if (token) {
            request = request.clone({
                setHeaders: {
                    authorization: `Bearer ${token}`
                }
            });
        }
        return next.handle(request)
            .pipe(
                finalize(() => {
                    this._progressBarService.hide();
                })
            );
    }
}
