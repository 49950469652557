import {Injectable} from '@angular/core';
import {ApiService, BroadcastService, Response} from '@ai/ngx-concentric';
import {Observable, ReplaySubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Navigation} from 'app/core/interfaces/navigation.types';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NavigationService{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    // -----------------------------------------------------------------------------------------------------
    // Getter for navigation
    // -----------------------------------------------------------------------------------------------------
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    constructor(private _apiService: ApiService,
                private _broadcastService: BroadcastService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // Get all navigation data
    // -----------------------------------------------------------------------------------------------------
    get(passedInUnitId?: number): Observable<Navigation> {
        passedInUnitId = passedInUnitId ?? 0;
        return this._apiService.get<Response<Navigation>>(`${environment.bullhornApiUrl}/Navigation/GetNavigationModulesByUnitIdAsync?unitId=${passedInUnitId}`)
        .pipe(
            map(response => {
              this._navigation.next(response.result);
              return response.result;
            })
          );
    }

    // -----------------------------------------------------------------------------------------------------
    // Get all navigation data async
    // -----------------------------------------------------------------------------------------------------
    async getAsync(passedInUnitId?: number): Promise<Navigation> {
        passedInUnitId = passedInUnitId ?? 0;
        var response = await this._apiService.getAsync<Response<Navigation>>(`${environment.bullhornApiUrl}/Navigation/GetNavigationModulesByUnitIdAsync?unitId=${passedInUnitId}`).then();
        this._navigation.next(response.result);
        return response.result;
    }
}
