import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../core/shared.module';
import {BreadcrumbComponent} from './breadcrumb.component';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        BreadcrumbComponent
    ],
    imports: [
        RouterModule.forChild([]),
        SharedModule,
        MatIconModule,
        MatTooltipModule
    ],
    exports: [
        BreadcrumbComponent
    ]
})
export class BreadcrumbModule {
}
