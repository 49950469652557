import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, NgForm} from '@angular/forms';
import {ChannelReadingsViewModel, ChannelUpdateResponse, ChannelViewModel,} from './readings.model';
import {ApiService, BroadcastService, Response, StorageService} from '@ai/ngx-concentric';
import {Unit} from '../../../../core/models/unit.model';
import {BehaviorSubject, Subject, throwError} from 'rxjs';
import {ReadingsService} from './readings.service';
import {AlertService} from '../../../../core/services/alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BwctHubService} from '../../../../core/services/bwct-hub.service';
import {UnitService} from '../../../../core/services/unit.service';
import * as _ from 'lodash';
import {DataPointEnum} from '../../../../core/enums/data-point.enum';
import {ChannelDisabledResponse, PcsLabel} from '../channel-settings/channel-settings.models';
import {environment} from '../../../../../environments/environment';
import {ReportingService} from '../../reporting/reporting.service';
import { ChannelSettingsService } from '../channel-settings/channel-settings.service';
import { AnalogChannelModel, DigitalChannelModel, RM5AnalogChannels, RM5DigitalChannels } from './components/channel/channel.models';
import { UnitStyleEnum } from 'app/core/enums/unit-style.enum';
import { takeUntil } from 'rxjs/operators';
import { ChannelSettingsComponent } from '../channel-settings/channel-settings.component';

@Component({
    selector: 'readings',
    templateUrl: './readings.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ReadingsComponent implements OnInit, OnDestroy {
    @ViewChild('form') ngForm: NgForm;
    @Input() unitId: number;
    @Input() instanceId: number;
    @Input() showBreadcrumb: string = 'true'; // Used for MFE
    @Input() showUnitSearch: string = 'true'; // Used for MFE
    @Input() showCardTitle: string = 'true'; // Used for MFE
    @Input() cardColor: string = '#fff'; // Used for MFE
    public analogChannelForm: FormGroup;
    public digitalChannelForm: FormGroup;
    public settings: FormArray;
    public unit: Unit;
    public showInstanceId: boolean = false;
    public unitDataPoints: ChannelReadingsViewModel[];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _dataPointEnum = DataPointEnum;
    private _retryCount: number = 3;

    private _analogChannels: AnalogChannelModel[];
    private _digitalChannels: DigitalChannelModel[];

    constructor(private _activatedRoute: ActivatedRoute,
                private _apiService: ApiService,
                private _alertService: AlertService,
                private _broadcastService: BroadcastService,
                private _bwctHubService: BwctHubService,
                private _changeDetectorRef: ChangeDetectorRef,
                private _formBuilder: FormBuilder,
                private _readingsService: ReadingsService,
                private _reportingService: ReportingService,
                private _router: Router,
                private _storageService: StorageService,
                private _unitService: UnitService,
                private _channelSettingsService: ChannelSettingsService) {
        _storageService.watch().subscribe((key) => {
            switch(key){
                case "selectedUnitId":
                    this.unitId = +_storageService.get(key);
                    break;
                case "selectedUnitInstanceId":
                    this.instanceId = +_storageService.get(key);
                    break;
            }
        });
    }

    // -----------------------------------------------------------------
    // On Init
    // -----------------------------------------------------------------
    async ngOnInit(): Promise<void> {
        ChannelSettingsComponent.broadcastReadingsEvent = new BehaviorSubject({});
        if(this.instanceId) {
            localStorage.setItem('selectedUnitInstanceId', this.instanceId.toString());
        } else {
            if(localStorage.getItem('selectedUnitInstanceId') != null) {
                this.instanceId = +localStorage.getItem('selectedUnitInstanceId');
            }
            else {
                this.instanceId = 1;
            }
        }

        if (this.unitId) {
            localStorage.setItem('selectedUnitId', this.unitId.toString());
        }
        await this.subscribeToBroadcastAsync();
        this.unit = await this._unitService.getUnitAsync(this.unitId ?? null, this._activatedRoute);
        await this._unitService.getChannelReadingsAsync(this.unit.unitId, this.instanceId).then(async (result) => {
            this.unitDataPoints = result;
            await this.buildForm();
        });
        this._readingsService.getMeasurements().subscribe(async (response: ChannelUpdateResponse) => {
            this.unitDataPoints = await this._unitService.getChannelReadingsAsync(this.unit.unitId, this.instanceId);
        });
    }


    // -----------------------------------------------------------------
    // Builds the readings form
    // -----------------------------------------------------------------
    async buildForm(): Promise<void> {
        this.showInstanceId = false;
        if (this.unit.brand.unitStyleId != UnitStyleEnum.SingleUnit)
        {
            this.showInstanceId = true;
        }
        this.unitDataPoints = await this._unitService.getChannelReadingsAsync(this.unit.unitId, this.instanceId);
        this._analogChannels = RM5AnalogChannels.get(this.unit?.brandId);
        this._digitalChannels = RM5DigitalChannels.get(this.unit?.brandId);

        this.buildAnalogFormControls();
        this.buildDigitalFormControls();
    }

    // -----------------------------------------------------------------
    // Builds the analog channel form
    // -----------------------------------------------------------------
    buildAnalogFormControls(): void {
        let configuration = this._formBuilder.group({});

        for(let channel of this._analogChannels)
        {
            configuration.addControl(channel.channelName, this._formBuilder.group({
                ac: _.find(this.unitDataPoints, {dataPointId: channel.analogACDataPointId})?.enabled,
                dc: _.find(this.unitDataPoints, {dataPointId: channel.analogDCDataPointId})?.enabled
            }));
        }

        this.analogChannelForm = this._formBuilder.group(configuration);
    }

    // -----------------------------------------------------------------
    // Builds the digital channel form
    // -----------------------------------------------------------------
    buildDigitalFormControls(): void {
        let configuration = this._formBuilder.group({});

        for(let channel of this._digitalChannels)
        {
            configuration.addControl(channel.channelName, this._formBuilder.group({
                enabled: _.find(this.unitDataPoints, {dataPointId: channel.digitalInputDataPointId}).enabled,
            }));
        }
        
        this.digitalChannelForm = this._formBuilder.group(configuration);
    }

    // -----------------------------------------------------------------
    // Enables or disables the channel
    // -----------------------------------------------------------------
    async toggleChannelReadings(isAnalog: boolean, datapointId: number): Promise<void> {
        const dataPoint = this.getDataPointById(datapointId);
        const enabled = dataPoint.enabled = !dataPoint.enabled;
        const payload = [{'path': '/Enabled', 'op': 'replace', 'value': enabled}];

        if (this.unit?.brand.unitStyleId == UnitStyleEnum.SingleUnit)
        {
            await this._readingsService.patchUnitDataPointAsync(payload, this.unit.unitId, dataPoint.dataPointId);
        }
        else
        {
            await this._readingsService.PatchChildUnitDataPointAsync(payload, this.unit.unitId, dataPoint.dataPointId, this.instanceId);
        }

        await this._reportingService.updateRoutineSteps(this.unit, this.unit.streamId, this.instanceId);
        // Channel disabled
        if (!enabled)
        {
            this._channelSettingsService.channelDisabled = {
                isAnalog: isAnalog,
                dataPointId: datapointId
            };
        }
    }

    // -----------------------------------------------------------------
    // Toggles the analog or digital channels settings
    // -----------------------------------------------------------------
    async toggleChannelSettingsSidebar(isAnalog: boolean, channelIndex: number, measurement: string): Promise<void> {
        let reading;
        let unitDataPoint;
        let accumulatorUnitDataPoint;
        let pcsLabels;
        if (isAnalog) {
            reading = this._readingsService.getAnalogFormControls(this.unit, channelIndex, measurement);
            reading.analogReading.channel_1 = channelIndex;
            unitDataPoint = await this._unitService.getChannelReadingByIdAsync(this.unit.unitId, this._dataPointEnum[`analog${channelIndex}${measurement.toUpperCase()}`], this.instanceId);
        } else {
            reading = this._readingsService.getDigitalFormControls(this.unit, channelIndex);
            reading.digitalReading.channel_1 = channelIndex;
            unitDataPoint = await this._unitService.getChannelReadingByIdAsync(this.unit.unitId, this._dataPointEnum[`digitalInput${channelIndex + 4}`], this.instanceId);
            accumulatorUnitDataPoint = await this._unitService.getChannelReadingByIdAsync(this.unit.unitId, this._dataPointEnum[`digitalAccumulator${channelIndex}`], this.instanceId);
        }

        pcsLabels = await this._readingsService.getPcsLabelsByBrandId(this.unit.brandId);
        const payload: ChannelViewModel = {
            isAnalog: isAnalog,
            unit: this.unit,
            unitDataPoint: unitDataPoint,
            reading: reading,
            measurement: measurement,
            channelIndex: channelIndex,
            pcsLabels: _.map(pcsLabels, 'label'),
            accumulatorUnitDataPoint: accumulatorUnitDataPoint ?? null,
            instanceId: this.instanceId
        };
        this._readingsService.channelViewModel = payload;
        ChannelSettingsComponent.broadcastReadingsEvent.next(payload);
        this._broadcastService.broadcast('readings:show-channel-settings', payload);
    }

    // -----------------------------------------------------------------
    // Toggles off all the analog alarms for a gaven datapoint
    // -----------------------------------------------------------------
    async disableAnalogAlarms(dataPointId: number) : Promise<void> {
        let request = null;
            
        const loPrefixMap = {
            [DataPointEnum.analog1AC]: 'lo_alarm_enable_ac_79',
            [DataPointEnum.analog1DC]: 'lo_alarm_enable_dc_59',
            [DataPointEnum.analog2AC]: 'lo_alarm_enable_ac_79',
            [DataPointEnum.analog2DC]: 'lo_alarm_enable_dc_59',
            [DataPointEnum.analog3AC]: 'lo_alarm_enable_ac_79',
            [DataPointEnum.analog3DC]: 'lo_alarm_enable_dc_59',
            [DataPointEnum.analog4AC]: 'lo_alarm_enable_ac_79',
            [DataPointEnum.analog4DC]: 'lo_alarm_enable_dc_59',
        };
        
        const hiPrefixMap = {
            [DataPointEnum.analog1AC]: 'hi_alarm_enable_ac_73',
            [DataPointEnum.analog1DC]: 'hi_alarm_enable_dc_53',
            [DataPointEnum.analog2AC]: 'hi_alarm_enable_ac_73',
            [DataPointEnum.analog2DC]: 'hi_alarm_enable_dc_53',
            [DataPointEnum.analog3AC]: 'hi_alarm_enable_ac_73',
            [DataPointEnum.analog3DC]: 'hi_alarm_enable_dc_53',
            [DataPointEnum.analog4AC]: 'hi_alarm_enable_ac_73',
            [DataPointEnum.analog4DC]: 'hi_alarm_enable_dc_53',
        };

        let channelMap = {};
        if (this.unit?.brand.unitStyleId == UnitStyleEnum.Dome  || this.unit?.brand.unitStyleId == UnitStyleEnum.IOModule)
        {
            channelMap = {
                [DataPointEnum.analog1AC]: '1001',
                [DataPointEnum.analog1DC]: '1001',
                [DataPointEnum.analog2AC]: '1002',
                [DataPointEnum.analog2DC]: '1002',
                [DataPointEnum.analog3AC]: '1003',
                [DataPointEnum.analog3DC]: '1003',
                [DataPointEnum.analog4AC]: '1004',
                [DataPointEnum.analog4DC]: '1004',
            };
        }
        else
        {
            channelMap = {
                [DataPointEnum.analog1AC]: '1',
                [DataPointEnum.analog1DC]: '1',
                [DataPointEnum.analog2AC]: '2',
                [DataPointEnum.analog2DC]: '2',
                [DataPointEnum.analog3AC]: '3',
                [DataPointEnum.analog3DC]: '3',
                [DataPointEnum.analog4AC]: '4',
                [DataPointEnum.analog4DC]: '4',
            };
        }
        
        const loPrefix = loPrefixMap[dataPointId];
        const hiPrefix = hiPrefixMap[dataPointId];
        const channelPrefix = channelMap[dataPointId];

        request = {};
        request['analog_acdc_31032'] = {};
        request['analog_acdc_31032'][channelPrefix] = {};
        request['analog_acdc_31032'][channelPrefix][loPrefix] = 0;
        request['analog_acdc_31032'][channelPrefix][hiPrefix] = 0;

        const requestPayload = JSON.stringify(request);
        await this._apiService.putAsync(`${environment.bullhornApiUrl}/Ota/SendOtaRequestAsync`, {
            deviceId: this.unit.streamId,
            request: requestPayload,
        }, this._retryCount).catch(err => {
            this._alertService.error(err);
        });
    }

    // -----------------------------------------------------------------
    // Toggles off all the digital alarms for a gaven datapoint
    // -----------------------------------------------------------------
    async disableDigitalAlarms(dataPointId: number) : Promise<void> {
        let request = null;
        
        let channelMap = {};
        if (this.unit?.brand.unitStyleId == UnitStyleEnum.Dome  || this.unit?.brand.unitStyleId == UnitStyleEnum.IOModule)
        {
            channelMap = {
                [DataPointEnum.digitalInput5]: '1001',
                [DataPointEnum.digitalInput6]: '1002'
            };
        }
        else
        {
            channelMap = {
                [DataPointEnum.digitalInput5]: '1',
                [DataPointEnum.digitalInput6]: '2'
            };
        }

        const channelPrefix = channelMap[dataPointId];

        request = {};
        request['digital_input_31021'] = {};
        request['digital_input_31021'][channelPrefix] = {};
        request['digital_input_31021'][channelPrefix]["lo_alarm_enabled_54"] = 0;
        request['digital_input_31021'][channelPrefix]["hi_alarm_enabled_51"] = 0;

        const requestPayload = JSON.stringify(request);
        await this._apiService.putAsync(`${environment.bullhornApiUrl}/Ota/SendOtaRequestAsync`, {
            deviceId: this.unit.streamId,
            request: requestPayload,
        }, this._retryCount).catch(err => {
            this._alertService.error(err);
        });
    }

    // -----------------------------------------------------------------
    // Subscribe To Broadcast events
    // -----------------------------------------------------------------
    async subscribeToBroadcastAsync(): Promise<void> {

        // -------------------------------------------------------------------------------
        // Triggered whenever a channel is disabled
        // -------------------------------------------------------------------------------
        this._channelSettingsService.channelDisabled$.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (response: ChannelDisabledResponse) => {
            if (response == null)
                return;

            if (response.isAnalog)
                this.disableAnalogAlarms(response.dataPointId);
            else
                this.disableDigitalAlarms(response.dataPointId);
        });

        this._broadcastService.subscribe('search:unitSelected', async (unit: Unit) => {
            this.unit = unit;
            this.instanceId = 1;
            await this.buildForm();
        });

        this._broadcastService.subscribe('search:unitInstanceSelected', async (instanceId: number) => {
            this.instanceId = instanceId;
            await this.buildForm();
        });

        this._activatedRoute.params.subscribe(async () => {
            if (this.unit) {
                await this.buildForm();
            }
        });

        this._broadcastService.subscribe('readings:update-channel-settings', async (data: any) => {
            this.unit = data.unit;
            if (this.unit.unitSettings) {
                await this.buildForm();
            }
        });

        this._bwctHubService.unit$.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (unit: Unit) => {
            this.unit = unit;
            if (this.unit.unitSettings) {
                await this.buildForm();
            }
        });
    }

    // -----------------------------------------------------------------
    // Get Datapoint
    // -----------------------------------------------------------------
    getDataPoint(datapointLookup: string): ChannelReadingsViewModel {
        return _.find(this.unitDataPoints, {dataPointId: this._dataPointEnum[datapointLookup]});
    }

    getDataPointById(dataPointId: number): ChannelReadingsViewModel {
        return _.find(this.unitDataPoints, {dataPointId: dataPointId});
    }

    // -----------------------------------------------------------------------------------------------------
    // ngOnDestroy
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
