import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {NavigationService} from 'app/core/services/navigation.service';
import {AuthService, UnitService, UserService} from '@ai/ngx-concentric';
import {UnitService as InternalUnitService} from './core/services/unit.service';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {

    constructor(private _authService: AuthService,
                private _navigationService: NavigationService,
                private _unitService: UnitService,
                private _internalInitService: InternalUnitService,
                private _userService: UserService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // Use this resolver to resolve initial internal data for the application
    // -----------------------------------------------------------------------------------------------------
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // Fork join multiple API endpoint calls to wait all of them to finish
        const sources: any[] = [
            this._navigationService.get(this._internalInitService.getUnitId()),
            this._userService.get(`${environment.bullhornApiUrl}/Users/GetUserById?id=${this._authService.userId}`)
        ];
        // Check if there is a unit ID in local storage to execute query with
        if (this._internalInitService.getUnitLookUpUrl()) {
            sources.push(this._unitService.get(this._internalInitService.getUnitLookUpUrl()));
        }
        // Return sources
        return forkJoin(sources);
    }
}
