import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Unit} from '../../../../core/models/unit.model';
import {BroadcastService, StorageService, UnitService} from '@ai/ngx-concentric';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {JwtInterceptor} from '../../../../core/interceptors/jwt.interceptor';

@Component({
    selector: 'unit-search-form',
    templateUrl: './unit-search-form.component.html'
})
export class UnitSearchFormComponent implements OnInit {
    @Input() unit: Unit;
    @Input() showUnitSearch: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private _broadcastService: BroadcastService,
                private _changeDetectorRef: ChangeDetectorRef,
                private _jwtInterceptor: JwtInterceptor,
                private _storageService: StorageService,
                private _unitService: UnitService) {
    }

    // -----------------------------------------------------------------
    // ngOnInit
    // -----------------------------------------------------------------
    async ngOnInit(): Promise<void> {
        await this.subscribeToBroadcastAsync();
        this._unitService.unit$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((unit: any) => {
                this.unit = unit;
                this._changeDetectorRef.markForCheck();
            });
    }

    // -----------------------------------------------------------------
    // Subscribe To Broadcast events
    // -----------------------------------------------------------------
    async subscribeToBroadcastAsync(): Promise<void> {
        this._broadcastService.subscribe('search:unitSelected', async (unit: Unit) => {
            this.unit = unit;
        });
    }
}
