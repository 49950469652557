import {Component} from '@angular/core';
import {BwctHubService} from './core/services/bwct-hub.service';
import {BroadcastService} from '@ai/ngx-concentric';
import {environment} from '../environments/environment';
const { version } = require('../../package.json');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public version = version;
    constructor(private _bwctHubService: BwctHubService,
                private _broadcastService: BroadcastService) {
        console.log(`v${version}`);
        console.log(`API ${environment.bullhornApiUrl}`);
    }
}
