import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ApiService, AuthService} from '@ai/ngx-concentric';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService {

    constructor(private _apiService: ApiService,
                private _authService: AuthService,
                private _cookieService: CookieService,
                private _router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // Validate the refresh token
    // -----------------------------------------------------------------------------------------------------
    public check(redirectURL: string, hasGuard: boolean): Observable<boolean> {
        // Validate the refresh token
        const url = `${environment.bullhornApiUrl}/Authentication/ValidateRefreshTokenAsync?refreshToken=${encodeURIComponent(this._authService.refreshToken)}`;
        return this._apiService.get<boolean>(url).pipe(switchMap((authenticated: any) => {

                // If the user is not authenticated and route has a guard
                if (!authenticated && hasGuard) {
                    // Redirect to the sign-in page
                    this._router.navigate(['sign-in'], {queryParams: {redirectURL}}).then();
                    // Prevent the access
                    return of(false);
                }

                // If the user is not authenticated and route does not have a guard
                if (authenticated && !hasGuard) {
                    // Redirect to the root
                    this._router.navigate(['']).then();
                    // Prevent the access
                    return of(false);
                }

                // Allow the access
                return of(true);
            })
        );
    }
}
