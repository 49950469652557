import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CoreConfirmationService } from '@core/services/confirmation/confirmation.service';
import { CoreConfirmationDialogComponent } from '@core/services/confirmation/dialog/dialog.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        CoreConfirmationDialogComponent
    ],
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        CommonModule
    ],
    providers   : [
        CoreConfirmationService
    ]
})
export class CoreConfirmationModule
{
    /**
     * Constructor
     */
    constructor(private _coreConfirmationService: CoreConfirmationService)
    {
    }
}
