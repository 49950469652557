import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {CORE_INTERNAL_API_DEFAULT_DELAY} from '@core/lib/internal-api/internal-api.constants';
import {CoreInternalApiInterceptor} from '@core/lib/internal-api/internal-api.interceptor';

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CoreInternalApiInterceptor,
            multi: true
        }
    ]
})
export class CoreInternalApiModule {
    /**
     * CoreInternalApi module default configuration.
     *
     * @param internalApiServices - Array of services that register internal API handlers
     * @param config - Configuration options
     * @param config.delay - Default delay value in milliseconds to apply all responses
     */
    static forRoot(internalApiServices: any[], config?: { delay?: number }): ModuleWithProviders<CoreInternalApiModule> {
        return {
            ngModule: CoreInternalApiModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    deps: [...internalApiServices],
                    useFactory: () => (): any => null,
                    multi: true
                },
                {
                    provide: CORE_INTERNAL_API_DEFAULT_DELAY,
                    useValue: config?.delay ?? 0
                }
            ]
        };
    }
}
