<div id="search-prompt" *ngIf="!unit">
    <div class="absolute inset-0">
        <!-- Main -->
        <div class="flex flex-col flex-auto items-center justify-center h-full p-6 sm:p-10">
            <!-- @formatter:off -->
            <div class="w-full max-w-3xl">
                <div class="bg-accent-300 border-t-4 border-blue-600 rounded-b text-teal-900 px-4 py-3 shadow-md"
                     role="alert">
                    <div class="flex">
                        <div class="py-1">
                            <svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20">
                                <path
                                    d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                            </svg>
                        </div>
                        <div>
                            <p class="font-bold">Configure your Bullhorn 5 series RMU</p>
                            <p class="text-sm">To get started, please search for the unit you want to configure</p>
                        </div>
                    </div>
                    <div class="mt-4">
                        <unit-search-bar id="search-prompt-component" [appearance]="'basic'"></unit-search-bar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
