import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Unit } from '../../../core/models/unit.model';
import { ApiService, BroadcastService, Dictionary, Response, StorageService } from '@ai/ngx-concentric';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UnitService as InternalUnitService, UnitService } from '../../../core/services/unit.service';
import { InterruptionModeEnum } from './interruption.enums';
import { InterruptionService } from './interruption.service';
import { DatePipe } from '@angular/common';
import { AlertService } from '../../../core/services/alert.service';
import { FormService } from '../../../core/services/form.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BwctHubService } from '../../../core/services/bwct-hub.service';
import { environment } from '../../../../environments/environment';
import { InterruptionFormResponse } from './interruption.models';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { TimeZone } from '../../../core/models/time-zone';
import { ChannelReadingsViewModel } from '../readings-and-settings/readings/readings.model';
import { DataPointEnum } from 'app/core/enums/data-point.enum';
import { UnitStyleEnum } from 'app/core/enums/unit-style.enum';
import { ReadingsService } from '../readings-and-settings/readings/readings.service';
import { ReportingService } from '../reporting/reporting.service';

@Component({
    selector: 'interruption',
    templateUrl: './interruption.component.html',
    styleUrls: ['./interruption.component.scss']
})
export class InterruptionComponent implements OnInit, OnDestroy {
    @Input() unitId: number;
    @Input() instanceId: number;
    @Input() showBreadcrumb: string = 'true'; // Used for MFE
    @Input() showUnitSearch: string = 'true'; // Used for MFE
    @Input() showCardTitle: string = 'true'; // Used for MFE
    @Input() cardColor: string = '#fff'; // Used for MFE
    public unit: Unit;
    public showInstanceId: boolean = false;
    public instantOffUnitDataPoint: ChannelReadingsViewModel;
    public form: FormGroup;
    public timeZoneForm: FormGroup;
    public payloadForm: FormGroup;
    public interruptionModeEnum = InterruptionModeEnum;
    public interruptionModes: Dictionary<string, number>[] = [];
    public interruptionFormResponse: InterruptionFormResponse;
    public timeZones: TimeZone[] = [];
    public selectedTimeZone: TimeZone;
    public filteredTimeZoneText: any;
    public searchModeOption: string = 'contains';
    public searchExprOption: any = ['timeZoneName'];
    public searchTimeoutOption: number = 200;
    public minSearchLengthOption: number = 0;
    private _unsubscribeAll: Subject<any> = new Subject();
    public channels: Dictionary<number, string>[] = [];

    constructor(private _activatedRoute: ActivatedRoute,
        private _apiService: ApiService,
        private _alertService: AlertService,
        private _broadcastService: BroadcastService,
        private _bwctHubService: BwctHubService,
        private _datePipe: DatePipe,
        private _formBuilder: FormBuilder,
        private _formService: FormService,
        private _internalUnitService: InternalUnitService,
        private _router: Router,
        private _storageService: StorageService,
        private _unitService: UnitService,
        private _reportingService: ReportingService,
        private _readingsService: ReadingsService,
        public _interruptionService: InterruptionService) {
        _storageService.watch().subscribe((key) => {
            switch (key) {
                case "selectedUnitId":
                    this.unitId = +_storageService.get(key);
                    break;
                case "selectedUnitInstanceId":
                    this.instanceId = +_storageService.get(key);
                    break;
            }
        });
    }

    // -----------------------------------------------------------------
    // On Init
    // -----------------------------------------------------------------
    async ngOnInit(): Promise<void> {
        if (this.unitId) {
            localStorage.setItem('selectedUnitId', this.unitId.toString());
        }
        if (this.instanceId) {
            localStorage.setItem('selectedUnitInstanceId', this.instanceId.toString());
        } else {
            if (localStorage.getItem('selectedUnitInstanceId') != null) {
                this.instanceId = +localStorage.getItem('selectedUnitInstanceId');
            }
            else {
                this.instanceId = 1;
            }
        }
        this.interruptionModes = this._interruptionService.getInterruptionModes();
        this.unit = await this._internalUnitService.getUnitAsync(this.unitId ?? null, this._activatedRoute);
        await this.buildForm();
        this.timeZones = await this._interruptionService.getAllTimeZonesAsync();
        this.setTimeZoneSelection();
        await this.subscribeToBroadcastAsync();
    }

    // -----------------------------------------------------------------
    // Builds the interruption form
    // -----------------------------------------------------------------
    async buildForm(): Promise<void> {
        this.instantOffUnitDataPoint = await this._unitService.getChannelReadingByIdAsync(this.unit?.unitId, DataPointEnum.instantOff, this.instanceId);
        this.interruptionFormResponse = this._interruptionService.getInterruptionFormControls(this.unit, this.instanceId);
        this.channels = this._interruptionService.getChannelsByUnit(this.unit);

        this.showInstanceId = false;
        if (this.unit.brand.unitStyleId != UnitStyleEnum.SingleUnit) {
            this.showInstanceId = true;
        }

        let form = this._formBuilder.group({
            interruption_mode_0: [this.interruptionFormResponse.interruptionModel.interruption_mode_0, [Validators.required]],
            on_time_1: [this.interruptionFormResponse.interruptionModel.on_time_1, [Validators.required]],
            off_time_2: [this.interruptionFormResponse.interruptionModel.off_time_2, [Validators.required, Validators.min(0.10)]],
            output_mode_3: [+this.interruptionFormResponse.interruptionModel.output_mode_3 !== 0, [Validators.required]],
            sync_mode_4: [+this.interruptionFormResponse.interruptionModel.sync_mode_4 !== 0, [Validators.required]],
            start_time_8: [this.interruptionFormResponse.interruptionModel.start_time_8, [Validators.required]],
            stop_time_9: [this.interruptionFormResponse.interruptionModel.stop_time_9, [Validators.required]],
            start_date_10: [this.interruptionFormResponse.interruptionModel.start_date_10, [Validators.required]],
            stop_date_11: [this.interruptionFormResponse.interruptionModel.stop_date_11, [Validators.required]],
            offset_15: [this.interruptionFormResponse.interruptionModel.offset_15, [Validators.required]],
            instant_off_channel_25: [this.interruptionFormResponse.interruptionModel.instant_off_channel_25, [Validators.required]],
            instant_off_enabled_24: [this.instantOffUnitDataPoint.enabled ?? false, [Validators.required]],
            instant_off_delay_26: [this.interruptionFormResponse.interruptionModel.instant_off_delay_26, [Validators.required, Validators.min(100), Validators.max(65535)]]
        });

        if (this._interruptionService.hasInterruptionVerification(this.unit)) {
            form.addControl('verification_enabled_23', new FormControl(this.interruptionFormResponse.interruptionModel.verification_enabled_23, [Validators.required]));
            form.addControl('upper_threshold_20', new FormControl(this.interruptionFormResponse.interruptionModel.upper_threshold_20, [Validators.required, Validators.min(1), Validators.max(10000)]));
            form.addControl('has_interruption', new FormControl(true, [Validators.required]));
        }

        if (this._interruptionService.hasInterferenceSupport(this.unit)) {
            form.addControl('number_of_units_5', new FormControl(this.interruptionFormResponse.interruptionModel.number_of_units_5, [Validators.required, Validators.min(1), Validators.max(99)]));
            form.addControl('unit_number_6', new FormControl(this.interruptionFormResponse.interruptionModel.unit_number_6, [Validators.required, Validators.min(1), Validators.max(99)]));
            form.addControl('delay_between_units_7', new FormControl(this.interruptionFormResponse.interruptionModel.delay_between_units_7, [Validators.required, Validators.min(1), Validators.max(9999)]));
            form.addControl('has_interference', new FormControl(true, [Validators.required]));
        }

        this.form = form;

        this.form.get('instant_off_enabled_24').valueChanges.subscribe((value: boolean) => {
            if (!value) {
                this.form.get('instant_off_channel_25').setValue(this.interruptionFormResponse.interruptionModel.instant_off_channel_25);
                this.form.get('instant_off_delay_26').setValue(this.interruptionFormResponse.interruptionModel.instant_off_delay_26);
                this.form.get('instant_off_channel_25').updateValueAndValidity();
                this.form.get('instant_off_delay_26').updateValueAndValidity();
                this.form.get('instant_off_channel_25').markAsPristine();
                this.form.get('instant_off_delay_26').markAsPristine();
            }
        });

        this.timeZoneForm = this._formBuilder.group({
            timeZoneId: [this.unit?.localTimeZone ?? null],
        });
        this.subscribeToFormChanges();
    }

    // -----------------------------------------------------------------
    // Watch form control value changes
    // -----------------------------------------------------------------
    subscribeToFormChanges(): void {
        this.form.get('interruption_mode_0').valueChanges.subscribe((value) => {
            this.form = this._interruptionService.UpdateForm(value, this.form);
        });
        this.form = this._interruptionService.subscribeToFormChanges(this.form);

        this.handleInterruptionVerificationToggle();
        this.handleInterferenceSupportSettings();
    }

    // -----------------------------------------------------------------
    // Increments the provided form value
    // -----------------------------------------------------------------
    increment(formControlName: string, useNumber: boolean = false, limit: number = 999.9): void {
        const currentFormControlValue = +this.form.controls[formControlName].value;
        if ((currentFormControlValue + (useNumber ? 1 : 0.10)) > limit) {
            return;
        }
        const value = +this.form.get(formControlName).value + (useNumber ? 1 : 0.10);
        this.form.controls[formControlName].setValue(value.toFixed(1));
        this.form.controls[formControlName].markAsDirty();
    }

    // -----------------------------------------------------------------
    // Decrements the provided form value
    // -----------------------------------------------------------------
    decrement(formControlName: string, useNumber: boolean = false, limit: number = 0.0): void {
        const currentFormControlValue = +this.form.controls[formControlName].value;
        if ((currentFormControlValue - 0.10) < 0.10 && formControlName === 'off_time_2') {
            return;
        }
        if ((currentFormControlValue - (useNumber ? 1 : 0.10)) < limit) {
            return;
        }
        const value = +this.form.get(formControlName).value - (useNumber ? 1 : 0.10);
        this.form.controls[formControlName].setValue(value.toFixed(1));
        this.form.controls[formControlName].markAsDirty();
    }

    // -----------------------------------------------------------------
    // Updates the form controls based on the interrupting mode
    // -----------------------------------------------------------------
    interruptionModeChanged(): void {
        this.form = this._interruptionService.recreateForm(this.unit, this.form, this._formBuilder, this.instanceId);
        for (let el in this.form.controls) {            
                this.form.controls[el].setErrors(null);
                this.form.controls[el].clearValidators();
                if(this.form.controls[el].value === null || this.form.controls[el].value === '' || this.form.controls[el].value === 0){
                    this.form.controls[el].removeValidators(Validators.required);
                }       
                if(el == 'offset_15'){
                    this.form.controls[el].setValue(this.selectedTimeZone?.timeZoneId);
                }                 
                this.form.controls[el].updateValueAndValidity();
        }        
        this.form.updateValueAndValidity();
        setTimeout(() => {
            this.validateForm();
            this.subscribeToFormChanges();
        }, 100);
    }

    // -----------------------------------------------------------------
    // Saves the readings form
    // -----------------------------------------------------------------
    async saveSettingsAsync(): Promise<void> {
        // validate form
        if (!this.validateForm()) {
            return;
        }

        // If no changes were made
        if (Object.keys(this.payloadForm.controls).length === 0) {
            this._alertService.info(`No changes were made to ${this.unit.clientUnitName}`);
            return;
        }
        // we need to save the new Instant - off if that toggle was changed
        if (this.payloadForm.controls?.['instant_off_enabled_24']?.dirty) {
            // patch unit data point
            await this.handleUnitDataPointPatchAsync(this.payloadForm);
        }
        
        // Create request
        if (this.form.controls.interruption_mode_0.value === this.interruptionModeEnum.startStop && this.form.controls['offset_15']?.value === this.selectedTimeZone?.timeZoneId)
            this.handleTimeZoneSelectedEvent({ value: this.selectedTimeZone?.timeZoneId });
        const payloadRequest = JSON.stringify(this._interruptionService.buildJsonPayload(this.form, this.payloadForm, this.unit, this.instanceId));

        // Submit request
        await this._apiService.putAsync(`${environment.bullhornApiUrl}/Ota/SendOtaRequestAsync`, {
            deviceId: this.unit.streamId,
            request: payloadRequest,
        }).then(async () => {
            // Patch the unit's time zone after the device twin has been updated
            await this.patchUnitTimeZoneAsync(this.payloadForm);
            this._alertService.success(`${this.unit.clientUnitName} has been updated`);
        });

        // update routine steps here
        await this._reportingService.updateRoutineSteps(this.unit, this.unit.streamId, this.instanceId);
    }

    // -----------------------------------------------------------------
    // Handles the patch Unit Data Point request
    // -----------------------------------------------------------------
    async handleUnitDataPointPatchAsync(payloadForm: FormGroup): Promise<void> {
        const payload = [];
        // Patch Instant off toggle
        if (payloadForm.controls?.['instant_off_enabled_24']?.value !== undefined) {
            payload.push({
                'path': '/Enabled',
                'op': 'replace',
                'value': +payloadForm.controls?.['instant_off_enabled_24']?.value
            });

            if (this.unit?.brand.unitStyleId == UnitStyleEnum.SingleUnit) {
                await this._readingsService.patchUnitDataPointAsync(payload, this.unit.unitId, this.instantOffUnitDataPoint.dataPointId).catch();
            }
            else {
                await this._readingsService.PatchChildUnitDataPointAsync(payload, this.unit.unitId, this.instantOffUnitDataPoint.dataPointId, this.instanceId).catch();
            }
        }
    }

    // -----------------------------------------------------------------
    // validates the form
    // -----------------------------------------------------------------
    validateForm(): boolean {
        // Build payload form based on dirty controls
        this.payloadForm = this._formBuilder.group({});
        Object.keys(this.form.controls).forEach((key) => {
            this.form.controls[key].markAsTouched();
            if (this.form.controls[key].dirty) {
                this.payloadForm.addControl(key, this.form.controls[key]);
            }
        });

        // Validate Time Zone
        if (this.form.controls.interruption_mode_0.value === InterruptionModeEnum.startStop) {
            this._interruptionService.validateTimeZone(this.form, this.timeZoneForm, this.selectedTimeZone);
        }

        // Validate Dates
        this._interruptionService.validateDateTimes(this.form);

        // Validate On / Off Times
        this._interruptionService.validateOnOffTimes(this.form);

        // Validate form
        return !this.form.invalid;
    }

    // -----------------------------------------------------------------
    // Subscribe To Broadcast events
    // -----------------------------------------------------------------
    async subscribeToBroadcastAsync(): Promise<void> {
        this._broadcastService.subscribe('search:unitSelected', async (unit: Unit) => {
            this.unit = unit;
            this.instanceId = 1;
            await this.buildForm();
            this.setTimeZoneSelection();
        });

        this._broadcastService.subscribe('search:unitInstanceSelected', async (instanceId: number) => {
            this.instanceId = instanceId;
            await this.buildForm();
        });

        this._activatedRoute.params.subscribe(async () => {
            if (this.unit) {
                await this.buildForm();
            }
        });

        this._bwctHubService.unit$.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (unit: Unit) => {
            this.unit = unit;
            if (this.unit.unitSettings) {
                await this.buildForm();
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // Handle the time zone change event
    // -----------------------------------------------------------------------------------------------------
    handleTimeZoneSelectedEvent(event: any): void {
        if (!event.value && event.value < 0) {
            this.form.controls.offset_15.setErrors({ valueRequired: 'A time zone must be selected' });
            this.form.controls.offset_15.markAsTouched();
            return;
        }
        this.selectedTimeZone = _.find(this.timeZones, { timeZoneId: event.value });
        this.form.controls.offset_15.setValue(this._interruptionService.calculateUtcOffset(this.selectedTimeZone, this.unit.observeDst));
        this.form.controls.offset_15.markAsDirty();
    }

    // -----------------------------------------------------------------------------------------------------
    // Set time zone
    // -----------------------------------------------------------------------------------------------------
    setTimeZoneSelection(): void {
        if (!this.unit?.localTimeZone) {
            return;
        }
        // Get the time zone ID from the unit (which is set in bullhorn web)
        this.selectedTimeZone = _.find(this.timeZones, { timeZoneId: this?.unit?.localTimeZone });
        const timeZoneText = _(this.timeZones).filter(item => item.timeZoneId === this?.unit?.localTimeZone).first().timeZoneName;

        if (!!this.selectedTimeZone) {
            this.filteredTimeZoneText = this.selectedTimeZone.timeZoneName;
            this.form.controls.offset_15.setValue(this._interruptionService.calculateUtcOffset(this.selectedTimeZone, this.unit.observeDst));
            this.form.controls.offset_15.markAsDirty();
        }

        // If the device twin doesn't have a reported offset_15 resource set then we need to make the user select one
        // If the input mode is Daily, continuous, or start/stop
        if (!this.form.controls.offset_15?.value &&
            (this.form.controls.interruption_mode_0.value === InterruptionModeEnum.continuous ||
                this.form.controls.interruption_mode_0.value === InterruptionModeEnum.startStop)) {
            this.form.controls.offset_15.setErrors({ valueRequired: 'A time zone must be selected' });
            this.form.controls.offset_15.markAsTouched();
            return;
        }
    }


    // -----------------------------------------------------------------
    // listens to the Interruption Verification Toggle
    // -----------------------------------------------------------------
    handleInterruptionVerificationToggle(): void {
        if (!this._interruptionService.hasInterruptionVerification(this.unit)) {
            return;
        }
        this.form.get(`verification_enabled_23`).valueChanges.subscribe((value) => {
            if (!value) {
                this.form.removeControl(`upper_threshold_20`);
            } else {
                const thresholdKey = 'upper_threshold_20';
                this.form.addControl(thresholdKey, new FormControl(this.form.get[thresholdKey]?.value
                    ?? this._interruptionService.getInterruptionFormControls(this.unit, this.instanceId).interruptionModel[thresholdKey],
                    [Validators.required, Validators.min(1), Validators.max(10000)]));
            }
        }
        );
    }

    // -----------------------------------------------------------------
    // Handles the patch Unit Data Point request
    // -----------------------------------------------------------------
    async patchUnitTimeZoneAsync(payloadForm: FormGroup): Promise<void> {
        // Do not patch the unit if interruption modes is:
        if (this.form.controls.interruption_mode_0.value === InterruptionModeEnum.interruptionOff ||
            this.form.controls.interruption_mode_0.value === InterruptionModeEnum.cathodicProtectionOff) {
            return;
        }

        // Patch the unit
        const payload = [];
        if (payloadForm.controls?.['offset_15']?.value) {
            payload.push({
                'path': '/LocalTimeZone',
                'op': 'replace',
                'value': this.selectedTimeZone.timeZoneId
            });
        }
        if (payload.length > 0) {
            const path = `${environment.bullhornApiUrl}/Units/PatchUnitAsync?unitId=${this.unit.unitId}`;
            await this._apiService.patchAsync<Response<Unit>>(path, payload).then();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // ngOnDestroy
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // ----------------------------------------------------------------
    // handles interference support settings form control changes
    // -----------------------------------------------------------------
    handleInterferenceSupportSettings(): void {
        if (!this._interruptionService.hasInterferenceSupport(this.unit)) {
            return;
        }
        this.handleNumberOfUnitsChanges();
        this.handleUnitNumberChanges();
    }

    // ----------------------------------------------------------------
    // handles number of unit form controls changes
    // -----------------------------------------------------------------
    handleNumberOfUnitsChanges(): void {
        const numberOfUnitsControl = this.form.get(`number_of_units_5`);

        // This code block ensures that the numberOfUnitsControl value is always greater than or equal
        // to the unitNumberControl value, and updates the validation errors accordingly.
        numberOfUnitsControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
            const unitNumber = +this.form.get(`unit_number_6`).value;
            // Set the error on the control
            if (unitNumber > +value) {
                numberOfUnitsControl.setErrors({ tooSmall: `"Number of units" cannot be less than the unit's number in the (I&I) study` });
                numberOfUnitsControl.markAsTouched();
            }
            // Remove the error from the control by passing null to setErrors
            if (unitNumber <= +value) {
                numberOfUnitsControl.setErrors(null, { emitEvent: false });
                // ensures that the numberOfUnitsControl value is required, and must be between 1 and 99, and updates the validation errors accordingly.
                numberOfUnitsControl.setValidators([Validators.required, Validators.min(1), Validators.max(99)]);
                numberOfUnitsControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
            }
            // After numberOfUnitsControls has been validated, validate unitNumberControl
            this.form.get(`unit_number_6`).updateValueAndValidity({ onlySelf: true, emitEvent: true });
        });
    }

    // ----------------------------------------------------------------
    // handles unit number form controls changes
    // -----------------------------------------------------------------
    handleUnitNumberChanges(): void {
        const unitNumberControl = this.form.get(`unit_number_6`);

        // This code block ensures that the unitNumberControl value is always less than or equal
        // to the numberOfUnitsControl value, and updates the validation errors and validators accordingly.
        unitNumberControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
            const numberOfUnits = +this.form.get(`number_of_units_5`).value;
            // Set the error on the control
            if (numberOfUnits < +value) {
                unitNumberControl.setErrors({ tooBig: `"My Number" cannot be greater than the unit's number in the (I&I) study` });
                unitNumberControl.markAsTouched();
            }
            // Remove the error from the control by passing null to setErrors
            if (numberOfUnits >= +value) {
                unitNumberControl.setErrors(null, { emitEvent: false });
                // ensures that the numberOfUnitsControl value is required, and must be between 1 and 99, and updates the validation errors accordingly.
                unitNumberControl.setValidators([Validators.required, Validators.min(1), Validators.max(99)]);
                unitNumberControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
            }
            // After numberOfUnitsControls has been validated, validate unitNumberControl
            this.form.get(`number_of_units_5`).updateValueAndValidity({ onlySelf: true, emitEvent: true });
        });
    }
}
