import {NgModule} from '@angular/core';
import {LayoutComponent} from 'app/layout/layout.component';
import {SharedModule} from 'app/core/shared.module';
import {SimpleLayoutModule} from './layouts/simple/simple.module';
import {EmptyLayoutModule} from './layouts/empty/empty.module';


@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        SharedModule,
        EmptyLayoutModule,
        SimpleLayoutModule,
    ],
    exports: [
        LayoutComponent,
        EmptyLayoutModule,
        SimpleLayoutModule
    ]
})
export class LayoutModule {
}
