import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../core/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PendingIndicatorComponent} from './pending-indicator.component';

@NgModule({
    declarations: [
        PendingIndicatorComponent
    ],
    imports: [
        RouterModule.forChild([]),
        SharedModule,
        MatIconModule,
        MatTooltipModule
    ],
    exports: [
        PendingIndicatorComponent
    ]
})
export class PendingIndicatorModule {
}
