//-----------------------------------------------
// Constants for brand type
//-----------------------------------------------
export enum BrandType {
    RM510C = 65,
    RM510CIO4 = 66,
    RM510S = 67,
    RM520C= 68,
    RM520S = 69,
    RM530C= 70,
    RM530S = 71,
    RM540C= 72,
    RM540S = 73,
}