<span class="relative inline-block">
    <button id="channel-settings-save-button" *ngIf="!isProcessing" [disabled]="disabled"
            class="ml-4" mat-flat-button type="button" color="{{color}}"> {{text}}</button>
    <span *ngIf="hasErrors" class="absolute top-0 right-0 inline-block w-2 h-2 transform -translate-x-1.5 -translate-y-1/2">
        <div class="text-sm flex flex-row content-center">
            <mat-icon id="save-form-error" class="ml-1.5 icon-size-4 text-red-700"
                      [svgIcon]="'mat_solid:error'"
                      [matTooltip]="'There are one or more errors on the form'">
            </mat-icon>
        </div>
    </span>
</span>

<button *ngIf="isProcessing" type="button"
        class="ml-4 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-full text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
        disabled="">
    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
         fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    {{textWhenProcessing}}
</button>
