import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CoreMediaWatcherService} from '@core/services/media-watcher';
import {CoreNavigationService, CoreVerticalNavigationComponent} from '@core/components/navigation';
import {Navigation} from 'app/core/interfaces/navigation.types';
import {NavigationService} from 'app/core/services/navigation.service';
import {BroadcastService} from '@ai/ngx-concentric';
import { Unit } from 'app/core/models/unit.model';
const { version } = require('../../../../../package.json');

@Component({
    selector: 'simple-layout',
    templateUrl: './simple.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SimpleComponent implements OnInit, OnDestroy {
    public isScreenSmall: boolean;
    public navigation: Navigation;
    public isHttpLoading: boolean = false;
    public version = version;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _broadcastService: BroadcastService,
        private _coreMediaWatcherService: CoreMediaWatcherService,
        private _coreNavigationService: CoreNavigationService,
        private _navigationService: NavigationService,
        private _router: Router
    ) {
        this._broadcastService.subscribe('isHttpLoading', async (isHttpLoading: boolean) => {
            this.isHttpLoading = isHttpLoading;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // currentYear
    // -----------------------------------------------------------------------------------------------------
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // ngOnInit
    // -----------------------------------------------------------------------------------------------------
    async ngOnInit(): Promise<void> {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        this._broadcastService.subscribe('search:unitSelected', async (unit: Unit) => {
            await this._navigationService.getAsync(unit.unitId);
        });

        // Subscribe to media changes
        this._coreMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // ngOnDestroy
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // toggleNavigation
    // -----------------------------------------------------------------------------------------------------
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._coreNavigationService.getComponent<CoreVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
