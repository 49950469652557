import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CoreScrollbarModule} from '@core/directives/scrollbar/public-api';
import {CoreVerticalNavigationAsideItemComponent} from '@core/components/navigation/vertical/components/aside/aside.component';
import {CoreVerticalNavigationBasicItemComponent} from '@core/components/navigation/vertical/components/basic/basic.component';
import {CoreVerticalNavigationCollapsableItemComponent} from '@core/components/navigation/vertical/components/collapsable/collapsable.component';
import {CoreVerticalNavigationDividerItemComponent} from '@core/components/navigation/vertical/components/divider/divider.component';
import {CoreVerticalNavigationGroupItemComponent} from '@core/components/navigation/vertical/components/group/group.component';
import {CoreVerticalNavigationSpacerItemComponent} from '@core/components/navigation/vertical/components/spacer/spacer.component';
import {CoreVerticalNavigationComponent} from '@core/components/navigation/vertical/vertical.component';

@NgModule({
    declarations: [
        CoreVerticalNavigationAsideItemComponent,
        CoreVerticalNavigationBasicItemComponent,
        CoreVerticalNavigationCollapsableItemComponent,
        CoreVerticalNavigationDividerItemComponent,
        CoreVerticalNavigationGroupItemComponent,
        CoreVerticalNavigationSpacerItemComponent,
        CoreVerticalNavigationComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        CoreScrollbarModule
    ],
    exports: [
        CoreVerticalNavigationComponent
    ]
})
export class CoreNavigationModule {
}
