export enum DataPointEnum {
    digitalChannel1 = 1601,
    digitalChannel2,
    analog1AC,
    analog1DC,
    analog1InstantOff,
    analog2AC,
    analog2DC,
    analog2InstantOff,
    analog3AC,
    analog3DC,
    analog3InstantOff,
    analog4AC,
    analog4DC,
    analog4InstantOff,
    analog1AcHigh,
    analog1AcLow,
    analog1DcHigh,
    analog1DcLow,
    analog2AcHigh,
    analog2AcLow,
    analog2DcHigh,
    analog2DcLow,
    analog3AcHigh,
    analog3AcLow,
    analog3DcHigh,
    analog3DcLow,
    analog4AcHigh,
    analog4AcLow,
    analog4DcHigh,
    analog4DcLow,
    digitalInput5,
    digitalInput6,
    instantOff = 1654,
    digitalAccumulator1 = 1655,
    digitalAccumulator2 = 1656
}
