import { NgModule } from '@angular/core';
import { CoreMediaWatcherService } from '@core/services/media-watcher/media-watcher.service';

@NgModule({
    providers: [
        CoreMediaWatcherService
    ]
})
export class CoreMediaWatcherModule
{
    /**
     * Constructor
     */
    constructor(private _coreMediaWatcherService: CoreMediaWatcherService)
    {
    }
}
