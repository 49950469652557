import { NgModule } from '@angular/core';
import { CoreUtilsService } from '@core/services/utils/utils.service';

@NgModule({
    providers: [
        CoreUtilsService
    ]
})
export class CoreUtilsModule
{
    /**
     * Constructor
     */
    constructor(private _coreUtilsService: CoreUtilsService)
    {
    }
}
