import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthGuardService} from "../services/auth-guard.service";

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private _authGuardService: AuthGuardService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // canActivate
    // -----------------------------------------------------------------------------------------------------
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    // -----------------------------------------------------------------------------------------------------
    // canActivateChild
    // -----------------------------------------------------------------------------------------------------
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check();
    }

    // -----------------------------------------------------------------------------------------------------
    // canLoad
    // -----------------------------------------------------------------------------------------------------
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    // -----------------------------------------------------------------------------------------------------
    // _check
    // -----------------------------------------------------------------------------------------------------
    private _check(): Observable<boolean> {
        return this._authGuardService.check(null, false);
    }
}
