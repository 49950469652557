import {NgModule} from '@angular/core';
import {CoreLongPressDirective} from './long-press.directive';
import {NumericDirective} from './numeric-only.directive';

@NgModule({
    declarations: [
        CoreLongPressDirective,
        NumericDirective
    ],
    exports: [
        CoreLongPressDirective,
        NumericDirective
    ]
})
export class CoreLongPressModule {
}
