<!------------------------------------------------------------>
<!-- Main Content -->
<!------------------------------------------------------------>
<div class="flex flex-col flex-auto min-w-0">

    <!------------------------------------------------------------>
    <!-- Breadcrumb -->
    <!------------------------------------------------------------>
    <breadcrumb *ngIf="showBreadcrumb === 'true'" breadcrumbTitle="Interruption"
                description="Configure an interruption schedule for your RMU"
                [unit]="unit" [instanceId]="instanceId" [showInstanceId]="showInstanceId">
    </breadcrumb>

    <!------------------------------------------------------------>
    <!-- Form -->
    <!------------------------------------------------------------>
    <div *ngIf="unit" class="flex flex-col flex-auto items-center p-6 sm:p-10">
        <div class="flex flex-col w-full max-w-4xl">
            <div style="background-color:{{cardColor}}!important"
                 class="pt-4 pl-6 pr-6 pb-3 shadow rounded-2xl bg-white">

                <!------------------------------------------------------------>
                <!-- Form Title -->
                <!------------------------------------------------------------>
                <div *ngIf="showCardTitle === 'true'" class="text-3xl font-bold pb-4">
                    <div class="bg-gray-100 px-4 py-3 rounded relative">
                        {{unit.clientUnitName}}<span *ngIf="showInstanceId == true">: I/O {{instanceId}}</span>
                    </div>
                </div>

                <!------------------------------------------------------------>
                <!-- Form -->
                <!------------------------------------------------------------>
                <form [formGroup]="form" *ngIf="form?.controls" id="interval-form" class="flex flex-col gt-sm:flex-row">
                    <div class="flex flex-col">
                        <!------------------------------------------------------------>
                        <!-- Interruption Settings -->
                        <!------------------------------------------------------------>
                        <div id="interruption-settings-container" class="pb-4">
                            <div class="text-2xl font-bold tracking-tight">Interruption Settings</div>
                            <div class="flex flex-col">
                                <div class="flex items-center">
                                    <mat-label class="font-semibold">Interruption Settings</mat-label>
                                    <pending-indicator
                                        [show]="interruptionFormResponse?.interruptionPendingModel.interruption_mode_0">
                                    </pending-indicator>
                                </div>
                                <mat-form-field class="core-mat-dense flex-auto">
                                    <mat-select id="interruption-mode-select-box" formControlName="interruption_mode_0"
                                                placeholder="Choose an interruption mode"
                                                (selectionChange)="interruptionModeChanged()">
                                        <mat-select-trigger>
                                            {{_interruptionService.getInterruptionModeHint(form.controls.interruption_mode_0.value).key}}
                                        </mat-select-trigger>
                                        <ng-container *ngFor="let interruptionMode of interruptionModes">
                                            <mat-option
                                                class="h-auto py-4 leading-none"
                                                [value]="interruptionMode.value">
                                                <div class="font-medium">
                                                    {{interruptionMode.key | properCase | titlecase}}
                                                </div>
                                                <div class="text-sm whitespace-normal leading-normal text-secondary">
                                                    {{_interruptionService.getInterruptionModeHint(interruptionMode.value).value}}
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <mat-icon class="icon-size-5" matPrefix svgIcon="mat_solid:today">
                                    </mat-icon>
                                    <mat-hint>{{_interruptionService.getInterruptionModeHint(form.controls.interruption_mode_0.value).value}}</mat-hint>
                                    <mat-error>You must select an interruption mode</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <mat-divider class="pb-2"></mat-divider>

                        <!------------------------------------------------------------>
                        <!-- Sync Settings -->
                        <!------------------------------------------------------------>
                        <div *ngIf="form.controls.interruption_mode_0.value === interruptionModeEnum.startStop ||
                                form.controls.interruption_mode_0.value === interruptionModeEnum.continuous">
                            <div id="interruption-sync-container" class="pb-4">
                                <div class="text-2xl font-bold tracking-tight">Synchronization</div>
                                <div class="flex flex-col">

                                    <!------------------------------------------------------------>
                                    <!-- Time Zone -->
                                    <!------------------------------------------------------------>
                                    <div class="flex flex-col" *ngIf="form.controls.interruption_mode_0.value === interruptionModeEnum.startStop">
                                        <div class="flex items-center">
                                            <mat-label class="font-semibold">Interruption Program Time Zone</mat-label>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel.offset_15">
                                            </pending-indicator>
                                        </div>
                                        <mat-form-field class="core-mat-dense flex-auto" [formGroup]="timeZoneForm">
                                            <mat-select id="time-zone-select-box" placeholder="Choose a time zone"
                                                        formControlName="timeZoneId"
                                                        (selectionChange)="handleTimeZoneSelectedEvent($event)">
                                                <mat-option *ngFor="let timeZone of timeZones"
                                                            class="h-auto py-4 leading-none"
                                                            [value]="timeZone.timeZoneId">
                                                    <div class="font-medium">
                                                        {{timeZone.timeZoneName}}
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                            <mat-icon class="icon-size-5" matPrefix svgIcon="mat_solid:public"></mat-icon>
                                            <mat-hint *ngIf="!timeZoneForm.controls?.timeZoneId?.errors?.valueRequired" class="text-sm secondary-text">Select the unit's time zone</mat-hint>
                                            <mat-error *ngIf="timeZoneForm.controls?.timeZoneId?.errors?.valueRequired">{{timeZoneForm.controls?.timeZoneId?.errors?.valueRequired}}</mat-error>
                                        </mat-form-field>
                                        <mat-error class="-mt-4" *ngIf="timeZoneForm.controls?.timeZoneId?.errors?.valueRequired">{{timeZoneForm.controls?.timeZoneId?.errors?.valueRequired}}</mat-error>
                                    </div>
                                    <!------------------------------------------------------------>
                                    <!-- Sync Mode -->
                                    <!------------------------------------------------------------>
                                    <div id="sync-mode-container" class="pt-4 flex flex-col"
                                         *ngIf="form.controls.interruption_mode_0.value !== interruptionModeEnum.cathodicProtectionOff &&
                                     form.controls.interruption_mode_0.value !== interruptionModeEnum.interruptionOff">
                                        <div class="flex items-center">
                                            <mat-label class="font-semibold">Sync Mode</mat-label>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel.sync_mode_4">
                                            </pending-indicator>
                                        </div>
                                        <mat-slide-toggle id="sync-mode-toggle"
                                                          formControlName="sync_mode_4"
                                                          color="primary">
                                            {{form.controls.sync_mode_4.value === false || form.controls.sync_mode_4.value === 0 ? 'On/Off' : 'Off/On'}}
                                        </mat-slide-toggle>
                                        <mat-hint class="text-sm secondary-text">
                                            The relay's on/off cycle begins with
                                            the {{form.controls.sync_mode_4.value === false || form.controls.sync_mode_4.value === 0 ? 'on cycle' : 'off cycle'}}
                                        </mat-hint>
                                    </div>
                                </div>
                            </div>
                            <mat-divider class="pb-2"></mat-divider>
                        </div>

                        <!------------------------------------------------------------>
                        <!-- Start Date Container -->
                        <!------------------------------------------------------------>
                        <div *ngIf="form.controls.interruption_mode_0.value === interruptionModeEnum.startStop">
                            <div id="interruption-start-date-container" class="pt-1 pb-4">
                                <div class="text-2xl font-bold">Start/Stop Times</div>
                                <div class="grid sm:grid-cols-4 gap-4 w-full">
                                    <!-- Start Date -->
                                    <div class="sm:col-span-2"
                                         *ngIf="form.controls.interruption_mode_0.value !== interruptionModeEnum.continuous">
                                        <div class="flex items-center">
                                            <mat-label class="font-semibold">Start Date</mat-label>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel.start_date_10">
                                            </pending-indicator>
                                        </div>
                                        <mat-form-field class="w-full">
                                            <input id="start-date-picker" (focus)="startDatePicker.open()" matInput
                                                   type='text'
                                                   formControlName="start_date_10"
                                                   [placeholder]="'MM/DD/YYYY'" [matDatepicker]="startDatePicker">
                                            <mat-datepicker #startDatePicker></mat-datepicker>
                                            <mat-icon class="icon-size-5" matPrefix
                                                      [svgIcon]="'mat_solid:today'"></mat-icon>
                                            <mat-error *ngIf="form.controls.start_date_10?.errors?.timeError">
                                                {{form.controls.start_date_10?.errors?.timeError}}
                                            </mat-error>
                                            <mat-hint>The date interruption begins</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <!-- Stop Date -->
                                    <div *ngIf="form.controls.interruption_mode_0.value !== interruptionModeEnum.continuous"
                                         class="sm:col-span-2">
                                        <div class="flex items-center">
                                            <mat-label class="font-semibold">Stop Date</mat-label>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel.stop_date_11">
                                            </pending-indicator>
                                        </div>
                                        <mat-form-field class="w-full">
                                            <input id="stop-date-picker" (focus)="stopDatePicker.open()" matInput
                                                   type='text' [min]="form.controls.start_date_10.value"
                                                   formControlName="stop_date_11"
                                                   [placeholder]="'MM/DD/YYYY'" [matDatepicker]="stopDatePicker">
                                            <mat-datepicker #stopDatePicker></mat-datepicker>
                                            <mat-icon class="icon-size-5" matPrefix
                                                      [svgIcon]="'mat_solid:today'"></mat-icon>
                                            <mat-error *ngIf="form.controls.stop_date_11?.errors?.timeError">
                                                {{form.controls.stop_date_11?.errors?.timeError}}
                                            </mat-error>
                                            <mat-hint>The date interruption stops</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <!-- Start Time -->
                                    <div
                                        *ngIf="form.controls.interruption_mode_0.value !== interruptionModeEnum.continuous"
                                        class="sm:col-span-2">
                                        <div class="flex items-center">
                                            <mat-label class="font-semibold">Start Time</mat-label>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel.start_time_8">
                                            </pending-indicator>
                                        </div>
                                        <mat-form-field class="w-full">
                                            <input id="start-time-input" readonly type='text' matInput
                                                   formControlName="start_time_8"
                                                   [ngxTimepicker]="startTimePicker">
                                            <ngx-material-timepicker #startTimePicker></ngx-material-timepicker>
                                            <mat-icon class="icon-size-5" matPrefix
                                                      [svgIcon]="'mat_solid:alarm'"></mat-icon>
                                            <mat-error *ngIf="form.controls.start_time_8?.errors?.timeError">
                                                {{form.controls.start_time_8?.errors?.timeError}}
                                            </mat-error>
                                            <mat-hint>The time of day interruption begins</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <!-- Stop Time -->
                                    <div
                                        *ngIf="form.controls.interruption_mode_0.value !== interruptionModeEnum.continuous"
                                        class="sm:col-span-2">
                                        <div class="flex items-center">
                                            <mat-label class="font-semibold">Stop Time</mat-label>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel.stop_time_9">
                                            </pending-indicator>
                                        </div>
                                        <mat-form-field class="w-full">
                                            <input id="stop-time-input" readonly type='text' matInput
                                                   formControlName="stop_time_9"
                                                   [ngxTimepicker]="stopTimePicker">
                                            <ngx-material-timepicker #stopTimePicker></ngx-material-timepicker>
                                            <mat-icon class="icon-size-5" matPrefix
                                                      [svgIcon]="'mat_solid:alarm'"></mat-icon>
                                            <mat-error *ngIf="form.controls.stop_time_9?.errors?.timeError">
                                                {{form.controls.stop_time_9?.errors?.timeError}}
                                            </mat-error>
                                            <mat-hint>The time of day interruption stops</mat-hint>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <mat-divider class="pb-2"></mat-divider>
                        </div>

                        <!------------------------------------------------------------>
                        <!-- Interruption On/Off Times -->
                        <!------------------------------------------------------------>
                        <div *ngIf="form.controls.interruption_mode_0.value !== interruptionModeEnum.cathodicProtectionOff &&
                           form.controls.interruption_mode_0.value !== interruptionModeEnum.interruptionOff"
                             id="interruption-on-off-container" class="pt-1 pb-4">
                            <div class="flex flex-row content-center">
                                <div class="text-2xl font-bold">On/Off Times</div>
                                <mat-icon class="ml-1.5 mt-2 icon-size-4 text-hint"
                                          [svgIcon]="'mat_solid:info'"
                                          [matTooltip]="'The minimum Interruption pattern period is 1 second and is configurable in 100 millisecond intervals. For example, a 0.8 second On cycle implies an accompanying 0.2 second Off cycle. The maximum Interruption pattern period for On or Off is 10 seconds.'">
                                </mat-icon>
                            </div>

                            <div class="grid sm:grid-cols-4 gap-4 w-full">
                                <!-- On Time -->
                                <div class="sm:col-span-2">
                                    <div class="flex items-center">
                                        <mat-label class="font-semibold">On Time</mat-label>
                                        <pending-indicator
                                            [show]="interruptionFormResponse?.interruptionPendingModel.on_time_1">
                                        </pending-indicator>
                                    </div>
                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="decrement-onTime-button" mat-icon-button matPrefix type="button"
                                                longPress (onLongPressing)="decrement('on_time_1')"
                                                (click)="decrement('on_time_1')">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- OnTime input -->
                                        <input id="on-time-input" class="text-center" formControlName="on_time_1"
                                               inputmode="decimal" matInput NumberOnly maxlength="5" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="increment-onTime-button" mat-icon-button matSuffix type="button"
                                                longPress (onLongPressing)="increment('on_time_1')"
                                                (click)="increment('on_time_1')">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>The seconds for the on cycle of interruption</mat-hint>
                                        <mat-error
                                            *ngIf="form.controls.on_time_1?.errors?.missingValue">{{form.controls.on_time_1?.errors?.missingValue}}</mat-error>
                                        <mat-error
                                            *ngIf="form.controls.on_time_1?.errors?.totalTimeLessThanOne">{{form.controls.on_time_1?.errors?.totalTimeLessThanOne}}</mat-error>
                                        <mat-error
                                            *ngIf="form.controls.on_time_1?.errors?.totalTimeGreaterThanAllowed">{{form.controls.on_time_1?.errors?.totalTimeGreaterThanAllowed}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- Off Time -->
                                <div class="sm:col-span-2">
                                    <div class="flex items-center">
                                        <mat-label class="font-semibold">Off Time</mat-label>
                                        <pending-indicator
                                            [show]="interruptionFormResponse?.interruptionPendingModel.off_time_2">
                                        </pending-indicator>
                                    </div>
                                    <mat-form-field class="w-full">
                                        <!-- Decrement button -->
                                        <button id="decrement-offTime-button" mat-icon-button matPrefix
                                                type="button"
                                                longPress (onLongPressing)="decrement('off_time_2')"
                                                (click)="decrement('off_time_2')">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                        </button>
                                        <!-- Input -->
                                        <input id="off-time-input" class="text-center" formControlName="off_time_2"
                                               inputmode="decimal" matInput NumberOnly maxlength="5" [placeholder]="'0'">
                                        <!-- Increment button -->
                                        <button id="increment-offTime-button" mat-icon-button matSuffix
                                                type="button"
                                                longPress (onLongPressing)="increment('off_time_2')"
                                                (click)="increment('off_time_2')">
                                            <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                        </button>
                                        <mat-hint>The seconds for the off cycle of interruption</mat-hint>
                                        <mat-error
                                            *ngIf="form.controls.off_time_2?.errors?.missingValue">{{form.controls.off_time_2?.errors?.missingValue}}</mat-error>
                                        <mat-error
                                            *ngIf="form.controls.off_time_2?.errors?.totalTimeLessThanOne">{{form.controls.off_time_2?.errors?.totalTimeLessThanOne}}</mat-error>
                                        <mat-error
                                            *ngIf="form.controls.off_time_2?.errors?.totalTimeGreaterThanAllowed">{{form.controls.off_time_2?.errors?.totalTimeGreaterThanAllowed}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <mat-divider class=""></mat-divider>
                        </div>

                        <!------------------------------------------------------------>
                        <!-- Output & Sync Mode / Relay Settings -->
                        <!------------------------------------------------------------>
                        <div id="output-sync-mode-container" class="pb-4">
                            <div class="text-2xl font-bold tracking-tight">Relay Settings</div>
                            <div class="flex flex-col">
                                <!------------------------------------------------------------>
                                <!-- Output Mode -->
                                <!------------------------------------------------------------>
                                <div id="output-mode-container" class="flex flex-col">
                                    <div class="flex items-center">
                                        <mat-label class="font-semibold">Output Mode</mat-label>
                                        <pending-indicator
                                            [show]="interruptionFormResponse?.interruptionPendingModel.output_mode_3">
                                        </pending-indicator>
                                    </div>
                                    <mat-slide-toggle id="output-mode-toggle"
                                                      formControlName="output_mode_3"
                                                      color="primary">
                                        Normally  {{form.controls.output_mode_3.value === false || form.controls.output_mode_3.value === 0 ? 'Open' : 'Closed'}}
                                    </mat-slide-toggle>
                                    <mat-hint class="text-sm secondary-text">The relay
                                        is {{form.controls.output_mode_3.value === false || form.controls.output_mode_3.value === 0 ? 'open' : 'closed'}}
                                        when
                                        the input switch is unpowered and actuates
                                        to {{form.controls.output_mode_3.value === false || form.controls.output_mode_3.value === 0 ? 'closed' : 'open'}}
                                        when
                                        the input switch is energized.
                                    </mat-hint>
                                </div>
                            </div>
                        </div>
                        <mat-divider class="pb-1"></mat-divider>

                        <!------------------------------------------------------------>
                        <!-- Instant-Off -->
                        <!------------------------------------------------------------>
                        <div>
                            <div class="flex flex-col">
                                <div class="text-2xl font-bold tracking-tight">Instant Off</div>

                                <!------------------------------------------------------------>
                                <!-- Enable Toggle -->
                                <!------------------------------------------------------------>
                                <div class="flex flex-col pb-3">
                                    <div id="output-mode-container" class="flex flex-col">
                                        <div class="flex flex-col">
                                            <div class="flex items-center">
                                                <mat-label class="font-semibold">Instant Off Enabled</mat-label>
                                                <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel.instant_off_enabled_24"></pending-indicator>
                                            </div>
                                            <mat-slide-toggle id="instant-off-enabled-toggle"
                                                            formControlName="instant_off_enabled_24" color="primary">
                                                <div class="flex flex-row">
                                                    {{form.controls.instant_off_enabled_24.value === false ? 'Disabled' : 'Enabled'}}
                                                </div>
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!------------------------------------------------------------>
                            <!-- Channel -->
                            <!------------------------------------------------------------>
                            <div *ngIf="form.controls.instant_off_enabled_24.value" class="flex flex-col">
                                <div class="flex flex-row items-center">
                                    <div class="flex flex-wrap">
                                        <div class="flex flex-row items-center">
                                            <div class="font-bold tracking-tight">Instant-Off Channel</div>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel.instant_off_channel_25"></pending-indicator>
                                        </div>
                                    </div>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Channel Selection -->
                                <!------------------------------------------------------------>
                                <div id="instant-off-channels-container">
                                    <div class="flex flex-col">
                                        <mat-form-field>
                                            <mat-select id="instant-off-select-box" class="instant-off-select-box" formControlName="instant_off_channel_25"
                                                        placeholder="Choose a channel">
                                                <mat-option *ngFor="let day of channels" [value]="day.key">
                                                    {{day.value}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'mat_solid:settings_input_component'"></mat-icon>
                                            <mat-hint>Select a channel to have the instant-off feature applied</mat-hint>
                                            <mat-error>You must select a channel</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <!------------------------------------------------------------>
                            <!-- Instant-Off Delay -->
                            <!------------------------------------------------------------>
                            <div *ngIf="form.controls.instant_off_enabled_24.value" class="flex flex-col">
                                <!------------------------------------------------------------>
                                <!-- Display Settings -->
                                <!------------------------------------------------------------>
                                <div class="pb-4">
                                    <div class="">
                                        <div class="flex flex-row items-center">
                                            <div class="font-bold tracking-tight">Off Delay</div>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel.instant_off_delay_26"></pending-indicator>
                                        </div>

                                        <!------------------------------------------------------------>
                                        <!-- Delay -->
                                        <!------------------------------------------------------------>
                                        <div class="decimal-places-container">
                                            <mat-form-field class="w-full">
                                                <!-- Decrement button -->
                                                <button id="decrement" mat-icon-button matPrefix type="button"
                                                        longPress
                                                        (onLongPressing)="decrement('instant_off_delay_26', false, 100)"
                                                        (click)="decrement('instant_off_delay_26', false, 100)">
                                                    <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                                </button>
                                                <!-- ac_decimal_places input -->
                                                <input id="ac_decimal-places-input"
                                                    class="text-center"
                                                    formControlName="instant_off_delay_26"
                                                    type="textbox" NumberOnly decimals="0" inputmode="decimal" matInput
                                                    [placeholder]="'0'">
                                                <span matTextSuffix class="secondary-text">ms</span>
                                                <!-- Increment button -->
                                                <button id="increment" mat-icon-button matSuffix type="button"
                                                        longPress
                                                        (onLongPressing)="increment('instant_off_delay_26', false, 65535)"
                                                        (click)="increment('instant_off_delay_26', false, 65535)">
                                                    <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                                </button>
                                                <mat-hint>Select the amount of time before the electrical current shuts off</mat-hint>
                                                <mat-error>Off delay must be between 100ms and 65535ms</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-divider class="pb-2"></mat-divider>
                        </div>

                        <!------------------------------------------------------------>
                        <!-- Interruption Verification Settings -->
                        <!------------------------------------------------------------>
                        <div *ngIf="form.controls.has_interruption" id="verification-interruption-settings-container" class="pt-1 pb-4">
                            <div class="text-2xl font-bold tracking-tight">Interruption Verification Settings</div>
                            <div class="flex flex-col">
                                <!------------------------------------------------------------>
                                <!-- Interruption Verification Toggle -->
                                <!------------------------------------------------------------>
                                <div id="verification-interruption-enabled-container" class="flex flex-col">
                                    <div class="flex items-center">
                                        <mat-label class="font-semibold">Interruption Verification Enabled</mat-label>
                                        <pending-indicator
                                            [show]="interruptionFormResponse?.interruptionPendingModel?.verification_enabled_23">
                                        </pending-indicator>
                                    </div>
                                    <mat-slide-toggle id="verification-interruption-enabled-toggle"
                                                      formControlName="verification_enabled_23"
                                                      color="primary">
                                        {{form.controls.verification_enabled_23?.value === false || form.controls.verification_enabled_23?.value === 0 ? 'Disabled' : 'Enabled'}}
                                    </mat-slide-toggle>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Interruption Verification Threshold -->
                                <!------------------------------------------------------------>
                                <div class="mt-2 grid sm:grid-cols-4 gap-4 w-full" *ngIf="form.controls.verification_enabled_23?.value">

                                    <!------------------------------------------------------------>
                                    <!-- Interruption Verification Threshold Label and Pending Indicator -->
                                    <!------------------------------------------------------------>
                                    <div class="sm:col-span-2">
                                        <div class="flex items-center">
                                            <mat-label class="font-semibold">Interruption Verification Threshold</mat-label>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel.upper_threshold_20">
                                            </pending-indicator>
                                        </div>
                                        <mat-form-field class="w-full">
                                            <!------------------------------------------------------------>
                                            <!-- Decrement button -->
                                            <!------------------------------------------------------------>
                                            <button id="decrement-interruption-verification-threshold" mat-icon-button matPrefix type="button"
                                                    longPress (onLongPressing)="decrement('upper_threshold_20', true, 1)"
                                                    (click)="decrement('upper_threshold_20', true, 1)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                            </button>

                                            <!------------------------------------------------------------>
                                            <!-- upper threshold input -->
                                            <!------------------------------------------------------------>
                                            <input id="interruption-verification-threshold" class="text-center" formControlName="upper_threshold_20"
                                                   inputmode="number" matInput NumberOnly maxlength="5" [placeholder]="'0'">
                                            <span matTextSuffix class="secondary-text">mV</span>

                                            <!------------------------------------------------------------>
                                            <!-- Increment button -->
                                            <!------------------------------------------------------------>
                                            <button id="increment-interruption-verification-threshold" mat-icon-button matSuffix type="button"
                                                    longPress (onLongPressing)="increment('upper_threshold_20', true, 10000)"
                                                    (click)="increment('upper_threshold_20', true, 10000)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                            </button>

                                            <!------------------------------------------------------------>
                                            <!-- Hint and Errors -->
                                            <!------------------------------------------------------------>
                                            <mat-hint>Reference the On/Off measurements in the interruption verification packet to
                                            determine the right threshold. You want to make sure it's slightly above the off measurement</mat-hint>
                                            <mat-error *ngIf="form.controls.upper_threshold_20?.errors?.required">A value is required</mat-error>
                                            <mat-error *ngIf="form.controls.upper_threshold_20?.errors?.min">Min value is 1 mV</mat-error>
                                            <mat-error *ngIf="form.controls.upper_threshold_20?.errors?.max">Max value is 10,000 mV</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <mat-divider class="pb-1"></mat-divider>
                        </div>

                        <!------------------------------------------------------------>
                        <!-- Interference Support -->
                        <!------------------------------------------------------------>
                        <div *ngIf="form.controls.has_interference" id="interference-support-container" class="pt-1 pb-4">
                            <div class="text-2xl font-bold tracking-tight">Interference Support Settings</div>
                            <div class="flex flex-col">
                                <!------------------------------------------------------------>
                                <!-- Interference Number of Units -->
                                <!------------------------------------------------------------>
                                <div class="mt-2 grid sm:grid-cols-4 gap-4 w-full">
                                    <!------------------------------------------------------------>
                                    <!-- Number of units -->
                                    <!------------------------------------------------------------>
                                    <div class="sm:col-span-2">
                                        <div class="flex items-center">
                                            <mat-label class="font-semibold">Number of units</mat-label>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel?.number_of_units_5">
                                            </pending-indicator>
                                        </div>
                                        <mat-form-field class="w-full">
                                            <!------------------------------------------------------------>
                                            <!-- Decrement button -->
                                            <!------------------------------------------------------------>
                                            <button id="decrement-number-of-units" mat-icon-button matPrefix type="button"
                                                    longPress (onLongPressing)="decrement('number_of_units_5', true, 1)"
                                                    (click)="decrement('number_of_units_5', true, 1)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                            </button>

                                            <!------------------------------------------------------------>
                                            <!-- Number of units  -->
                                            <!------------------------------------------------------------>
                                            <input id="interference-number-of-units" class="text-center" formControlName="number_of_units_5"
                                                   inputmode="number" matInput NumberOnly maxlength="2" [placeholder]="'0'">

                                            <!------------------------------------------------------------>
                                            <!-- Increment button -->
                                            <!------------------------------------------------------------>
                                            <button id="increment-number-of-units" mat-icon-button matSuffix type="button"
                                                    longPress (onLongPressing)="increment('number_of_units_5', true, 99)"
                                                    (click)="increment('number_of_units_5', true, 99)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                            </button>

                                            <!------------------------------------------------------------>
                                            <!-- Hint and Errors -->
                                            <!------------------------------------------------------------>
                                            <mat-hint>Set the appropriate number of units in the interference & influence (I&I) study.
                                                Default is 1 for solo interrupter (no I&I study).</mat-hint>
                                            <mat-error *ngIf="form.controls.number_of_units_5?.errors?.required">A value is required</mat-error>
                                            <mat-error *ngIf="form.controls.number_of_units_5?.errors?.min">Min value is 1</mat-error>
                                            <mat-error *ngIf="form.controls.number_of_units_5?.errors?.max">Max value is 99</mat-error>
                                            <mat-error *ngIf="form.controls.number_of_units_5?.errors?.tooSmall">{{form.controls.number_of_units_5?.errors?.tooSmall}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Interference Unit Number -->
                                <!------------------------------------------------------------>
                                <div class="mt-2 grid sm:grid-cols-4 gap-4 w-full">
                                    <!------------------------------------------------------------>
                                    <!-- Unit Number -->
                                    <!------------------------------------------------------------>
                                    <div class="sm:col-span-2">
                                        <div class="flex items-center">
                                            <mat-label class="font-semibold">My Number</mat-label>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel?.unit_number_6">
                                            </pending-indicator>
                                        </div>
                                        <mat-form-field class="w-full">
                                            <!------------------------------------------------------------>
                                            <!-- Decrement button -->
                                            <!------------------------------------------------------------>
                                            <button id="decrement-unit-number" mat-icon-button matPrefix type="button"
                                                    longPress (onLongPressing)="decrement('unit_number_6', true, 1)"
                                                    (click)="decrement('unit_number_6', true, 1)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                            </button>

                                            <!------------------------------------------------------------>
                                            <!-- My Number  -->
                                            <!------------------------------------------------------------>
                                            <input id="interference-unit-number" class="text-center" formControlName="unit_number_6"
                                                   inputmode="number" matInput NumberOnly maxlength="2" [placeholder]="'0'">

                                            <!------------------------------------------------------------>
                                            <!-- Increment button -->
                                            <!------------------------------------------------------------>
                                            <button id="increment-unit-number" mat-icon-button matSuffix type="button"
                                                    longPress (onLongPressing)="increment('unit_number_6', true, 99)"
                                                    (click)="increment('unit_number_6', true, 99)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                            </button>

                                            <!------------------------------------------------------------>
                                            <!-- Hint and Errors -->
                                            <!------------------------------------------------------------>
                                            <mat-hint>This unit's number in the (I&I) study. Default is 1 which means no I&I study.</mat-hint>
                                            <mat-error *ngIf="form.controls.unit_number_6?.errors?.required">A value is required</mat-error>
                                            <mat-error *ngIf="form.controls.unit_number_6?.errors?.min">Min value is 1</mat-error>
                                            <mat-error *ngIf="form.controls.unit_number_6?.errors?.max">Max value is 99</mat-error>
                                            <mat-error *ngIf="form.controls.unit_number_6?.errors?.tooBig">{{form.controls.unit_number_6?.errors?.tooBig}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <!------------------------------------------------------------>
                                <!-- Delay Between Units -->
                                <!------------------------------------------------------------>
                                <div class="mt-2 grid sm:grid-cols-4 gap-4 w-full">
                                    <!------------------------------------------------------------>
                                    <!-- Unit Number -->
                                    <!------------------------------------------------------------>
                                    <div class="sm:col-span-2">
                                        <div class="flex items-center">
                                            <mat-label class="font-semibold">Delay Between Units</mat-label>
                                            <pending-indicator
                                                [show]="interruptionFormResponse?.interruptionPendingModel?.delay_between_units_7">
                                            </pending-indicator>
                                        </div>
                                        <mat-form-field class="w-full">
                                            <!------------------------------------------------------------>
                                            <!-- Decrement button -->
                                            <!------------------------------------------------------------>
                                            <button id="decrement-delay-between-units" mat-icon-button matPrefix type="button"
                                                    longPress (onLongPressing)="decrement('delay_between_units_7', true, 1)"
                                                    (click)="decrement('delay_between_units_7', true, 1)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:remove'"></mat-icon>
                                            </button>

                                            <!------------------------------------------------------------>
                                            <!-- Delay Between Units  -->
                                            <!------------------------------------------------------------>
                                            <input id="interference-delay-between-units" class="text-center" formControlName="delay_between_units_7"
                                                   inputmode="number" matInput NumberOnly maxlength="4" [placeholder]="'0'">

                                            <!------------------------------------------------------------>
                                            <!-- Increment button -->
                                            <!------------------------------------------------------------>
                                            <button id="increment-delay-between-units" mat-icon-button matSuffix type="button"
                                                    longPress (onLongPressing)="increment('delay_between_units_7', true, 9999)"
                                                    (click)="increment('delay_between_units_7', true, 9999)">
                                                <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:add'"></mat-icon>
                                            </button>

                                            <!------------------------------------------------------------>
                                            <!-- Hint and Errors -->
                                            <!------------------------------------------------------------>
                                            <mat-hint>The number of seconds between units in an (I&I) study</mat-hint>
                                            <mat-error *ngIf="form.controls.delay_between_units_7?.errors?.required">A value is required</mat-error>
                                            <mat-error *ngIf="form.controls.delay_between_units_7?.errors?.min">Min value is 1</mat-error>
                                            <mat-error *ngIf="form.controls.delay_between_units_7?.errors?.max">Max value is 9999</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <!------------------------------------------------------------>
                <!-- Actions -->
                <!------------------------------------------------------------>
                <div class="flex items-center justify-end">
                    <button id="readings-save-button" class="ml-2" mat-button [color]="'primary'" type="button"
                            (click)="saveSettingsAsync()"><span class="uppercase">Save</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!------------------------------------------------------------>
<!-- Search bar - Shown when unit is not selected -->
<!------------------------------------------------------------>
<unit-search-form *ngIf="showUnitSearch === 'true'" id="search-prompt"></unit-search-form>
