import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {AuthService, UnitService} from '@ai/ngx-concentric';
import {CookieService} from 'ngx-cookie-service';
import {AlertService} from '../services/alert.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private _isRefreshing = false;
    private _refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private _alertService: AlertService,
                private _authService: AuthService,
                private _cookieService: CookieService,
                private _unitService: UnitService) {
    }

    injectToken(request: HttpRequest<any>, token: string): any {
        return request.clone({headers: request.headers.set('authorization', `bearer ${token}`)});
    }

    // -----------------------------------------------------------------------------------------------------
    // Handle 401 and silently refresh token to keep user signed in
    // -----------------------------------------------------------------------------------------------------
    handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this._isRefreshing) {
            this._isRefreshing = true;
            this._refreshTokenSubject.next(null);
            const token = this._authService.refreshToken;
            if (token) {
                return this._authService.refreshTokenSignIn(`${environment.bullhornApiUrl}/Authentication/RefreshAccessTokenAsync`).pipe(
                    switchMap(() => {
                        this._isRefreshing = false;
                        this._refreshTokenSubject.next(this._authService.accessToken);
                        return next.handle(this.injectToken(request, this._authService.accessToken));
                    }),
                    catchError((err) => {
                        this._isRefreshing = false;
                        this._authService.signOut();
                        return throwError(err);
                    })
                );
            }
        } else {
            return this._refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => next.handle(this.injectToken(request, jwt))));
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // intercept
    // -----------------------------------------------------------------------------------------------------
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    if (!this._authService.refreshToken) {
                        console.log('Your access token cannot be refreshed because there was no refresh token found in your browser\'s storage.');
                        this._authService.signOut();
                        localStorage.removeItem('selectedUnitId');
                        this._unitService.unit = null;
                    }
                    return this.handle401Error(request, next);
                }
                if (error.status === 429) {
                    const message = `${error.statusText}. ${error.error.statusText}`;
                    this._alertService.error(message);
                    return throwError(message);
                }
                if (error.status !== 401) {
                    const retryHeader = request.headers.get('Retry-Policy');
                    const message = error.error.ErrorMessage || error.statusText;
                    if (retryHeader == null || retryHeader == 'false')
                    {
                        this._alertService.error(message);
                    }
                    return throwError(message);
                }
            })
        );
    }
}
