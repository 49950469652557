<div class="flex flex-col flex-row flex-0 justify-between pl-3 border-b bg-card dark:bg-transparent">
    <div class="flex flex-wrap">
        <div class="flex-col pt-1 pb-1">
            <!------------------------------------------------------------>
            <!-- Title -->
            <!------------------------------------------------------------>
            <div class="text-3xl font-bold">{{breadcrumbTitle}}</div>
            <div  *ngIf="description" style="margin-top: -4px" class="text-sm text-secondary leading-none">{{description}}</div>

            <!------------------------------------------------------------>
            <!-- Unit -->
            <!------------------------------------------------------------>
            <div *ngIf="unit" class="text-sm mt-1">
                <div class="flex flex-row flex-0">
                    <span class="font-semibold">{{unit.serialNumber}} : {{unit.clientUnitName}}<span *ngIf="showInstanceId == true">: I/O {{instanceId}}</span></span>
                    <mat-icon class="ml-1.5 icon-size-4 text-hint"
                            [svgIcon]="'mat_solid:info'"
                            [matTooltip]="'The selected unit\'s serial number followed by the unit\'s client name'">
                    </mat-icon>
                </div>
                <div>
                    <span class="font-semibold">Brand: {{unit.brand.brandName}}</span>
                </div>
            </div>
        </div>
    </div>
</div>



