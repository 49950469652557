import {ApplicationRef, DoBootstrap, ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MarkdownModule} from 'ngx-markdown';
import {CoreAppModule} from '@core';
import {CoreConfigModule} from '@core/services/config';
import {CoreInternalApiModule} from '@core/lib/internal-api';
import {CoreModule} from 'app/core/core.module';
import {appConfig} from 'app/core/config/app.config';
import {internalApiServices} from 'app/core/data';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {AppRoutingModule} from 'app/app.routing';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './core/interceptors/jwt.interceptor';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {BwctHubService} from './core/services/bwct-hub.service';
import {createCustomElement} from '@angular/elements';
import {ReportingComponent} from './modules/admin/reporting/reporting.component';
import {ReportingService} from './modules/admin/reporting/reporting.service';
import {APP_BASE_HREF, DatePipe} from '@angular/common';
import {SharedModule} from './core/shared.module';
import {EmptyLayoutModule} from './layout/layouts/empty/empty.module';
import {SimpleLayoutModule} from './layout/layouts/simple/simple.module';
import {InterruptionComponent} from './modules/admin/interruption/interruption.component';
import {InterruptionService} from './modules/admin/interruption/interruption.service';
import {ReadingsComponent} from './modules/admin/readings-and-settings/readings/readings.component';
import {ChannelPipe} from './modules/admin/readings-and-settings/channel-settings/channel-settings.pipe';
import {NgxMaskModule, IConfig} from 'ngx-mask';
import {ReadingsService} from './modules/admin/readings-and-settings/readings/readings.service';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,

        // Core, CoreConfig & CoreInternalAPI
        CoreAppModule,
        CoreConfigModule.forRoot(appConfig),
        CoreInternalApiModule.forRoot(internalApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        NgxMaskModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 3000,
            preventDuplicates: true,
            positionClass: 'toast-top-right'
        }),
        MarkdownModule.forRoot({}),
        NgxMaterialTimepickerModule,
        AutocompleteLibModule,

        // Modules required for microfrontend
        SharedModule,
        EmptyLayoutModule,
        SimpleLayoutModule
    ],
    providers: [
        {provide: APP_BASE_HREF, useValue: '/'},
        {provide: DatePipe, useClass: DatePipe},
        {provide: ChannelPipe, useClass: ChannelPipe},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        BwctHubService,
        ReportingService,
        InterruptionService,
        ReadingsService
    ],
    entryComponents: [ReportingComponent]
})
export class AppModule implements DoBootstrap {
    constructor(private _injector: Injector) {
    }

    ngDoBootstrap(appRef: ApplicationRef): void {
        // Required for MFE to render
        if (document.querySelector('app-root')) {
            appRef.bootstrap(AppComponent);
        }

        // Create list of MFE components
        const elements: any[] = [
            [ReportingComponent, 'reporting-mfe'],
            [InterruptionComponent, 'interruption-mfe'],
            [ReadingsComponent, 'readings-mfe']];

        // Loop through list of MFEs and create the custom elements
        for (const [component, name] of elements) {
            const htmlElement = createCustomElement(component, {injector: this._injector});
            customElements.define(name, htmlElement);
        }
    }
}


