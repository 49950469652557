import { NgModule } from '@angular/core';
import { CoreScrollbarDirective } from '@core/directives/scrollbar/scrollbar.directive';

@NgModule({
    declarations: [
        CoreScrollbarDirective
    ],
    exports     : [
        CoreScrollbarDirective
    ]
})
export class CoreScrollbarModule
{
}
