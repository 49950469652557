import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'readings-toggle-button',
    templateUrl: './readings-toggle-button.component.html',
    styleUrls: ['./readings-toggle-button.component.scss']
})
export class ReadingsToggleButtonComponent {
    @Input() rowColor: string;
    @Input() toggleButtonId: string;
    @Input() toggleButtonTextSize: string = 'text-md';
    @Input() toggleButtonTitle: string;
    @Input() toggleButtonSubTitle: string;
    @Input() toggleButtonBackgroundColor: string;
    @Input() datapointTextColor: string;
    @Input() dataPointName: string;
    @Input() showPending: boolean = null;
    @Output() toggleReading = new EventEmitter<any>();
    @Output() toggleSideBar = new EventEmitter<any>();
}
