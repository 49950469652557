import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {ToastrService} from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class AlertService {
    constructor(private router: Router, private toastr: ToastrService) {
    }

    info(message: string, keepAfterRouteChange = false, timeOut = 5000, title = null): void {
        this.toastr.info(message, title, {timeOut: timeOut});
    }

    success(message: string, keepAfterRouteChange = false, timeOut = 5000, title = null): void {
        this.toastr.success(message, title, {timeOut: timeOut});
    }

    error(message: string, keepAfterRouteChange = false, timeOut = 5000, title = null): void {
        this.toastr.error(message, title, {timeOut: timeOut});
    }
}
