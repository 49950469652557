import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {debounceTime, filter, map, takeUntil} from 'rxjs/operators';
import {coreAnimations} from '@core/animations/public-api';
import {ApiService, BroadcastService, Response, StorageLocationEnum, StorageService} from '@ai/ngx-concentric';
import {Unit} from '../../../../core/models/unit.model';
import {AlertService} from '../../../../core/services/alert.service';
import {UnitService} from '../../../../core/services/unit.service';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import { NavigationService } from 'app/core/services/navigation.service';
// @ts-ignore
const parseString = require('xml2js').parseString;

@Component({
    selector: 'unit-search-bar',
    templateUrl: './unit-search-bar.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'coreSearch',
    animations: coreAnimations
})
export class UnitSearchBarComponent implements OnChanges, OnInit, OnDestroy {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    public opened: boolean = false;
    public resultSets: any[];
    public searchControl: FormControl = new FormControl();
    public unitId: number;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private _alertService: AlertService,
                private _apiService: ApiService,
                private _activatedRoute: ActivatedRoute,
                private _broadcastService: BroadcastService,
                private _elementRef: ElementRef,
                private _httpClient: HttpClient,
                private _renderer2: Renderer2,
                private _storageService: StorageService,
                private _unitService: UnitService,
                private _navigationService: NavigationService) {
        _storageService.watch().subscribe((key) => {
            this.unitId = +_storageService.get(key);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // Host binding for component classes
    // -----------------------------------------------------------------------------------------------------
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // Setter for bar search input
    // -----------------------------------------------------------------------------------------------------
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef) {
        // If the value exists, it means that the search input
        // is now in the DOM and we can focus on the input..
        if (value)
            // Give Angular time to complete the change detection cycle
        {
            setTimeout(() => {
                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // Listen for changes
    // -----------------------------------------------------------------------------------------------------
    ngOnChanges(changes: SimpleChanges): void {
        // To prevent any issues, close the
        // search after changing the appearance
        if ('appearance' in changes) {
            this.close();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // On init
    // -----------------------------------------------------------------------------------------------------
    ngOnInit(): void {
        // Check if there is a selected unit
        if (localStorage.getItem('selectedUnitId') !== null) {
            this.unitId = +localStorage.getItem('selectedUnitId');
        }

        // Subscribe to the search field value changes
        this.searchControl.valueChanges
            .pipe(
                debounceTime(0),
                takeUntil(this._unsubscribeAll),
                map((value) => {
                    // Set the resultSets to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    if (!value) {
                        this.resultSets = null;
                    }
                    // Continue
                    return value;
                }),
                // Filter out undefined/null/false statements and also
                // filter out the values that are smaller than minLength
                filter(value => value)
            )
            .subscribe((value) => {
                const query = `${environment.bullhornApiUrl}/Units/GetUnitByLookupAsync?SearchText=${value}&BrandId=65&BrandId=67&BrandId=68&BrandId=69&BrandId=70&BrandId=71&BrandId=72&BrandId=73`;
                this._apiService.get<Response<any>>(query).subscribe((response) => {
                    // Get unit results
                    const data = [{
                        id: 'units',
                        label: 'Units',
                        results: response.result.data
                    }];
                    // Store the result sets
                    this.resultSets = response.result.data.length > 0 ? data : [];
                    // Execute the event
                    this.search.next(data);
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // On keydown of the search input
    // -----------------------------------------------------------------------------------------------------
    onKeydown(event: KeyboardEvent): void {
        // Listen for escape to close the search if the appearance is 'bar'
        if (event.code === 'Escape') {
            this.close();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // Open the search - Used in 'bar'
    // -----------------------------------------------------------------------------------------------------
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }
        // Open the search
        this.opened = true;
    }

    // -----------------------------------------------------------------------------------------------------
    // Close the search - Used in 'bar'
    // -----------------------------------------------------------------------------------------------------
    close(): void {
        // Clear the search input
        this.searchControl.setValue('');

        // Return if it's already closed
        if (!this.opened) {
            return;
        }
        // Close the search
        this.opened = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // Track by function for ngFor loops
    // -----------------------------------------------------------------------------------------------------
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // Track by function for ngFor loops
    // -----------------------------------------------------------------------------------------------------
    selectSearchItem(selection: any): void {
        this._apiService.get<Response<Unit>>(`${environment.bullhornApiUrl}/Units/GetRm5UnitByIdAsync?unitId=${selection.unitId}`)
            .subscribe((unitResponse) => {
                this.unitId = selection.unitId;
                const errorMessage = `The unit '${unitResponse.result.clientUnitName}' cannot be edited because it does not have a device twin`;
                if (!unitResponse.result.unitSettings) {
                    this._alertService.error(errorMessage);
                    return;
                }
                unitResponse.result.unitSettings = JSON.parse(unitResponse.result.unitSettings);
                if (unitResponse.result && !this._unitService.validateHasReportedProperties(unitResponse.result)) {
                    this._alertService.error(errorMessage);
                    return;
                }

                this._storageService.set('selectedUnitId', selection.unitId, StorageLocationEnum.local);
                this._storageService.set('selectedUnitInstanceId', "1", StorageLocationEnum.local);
                this._broadcastService.broadcast('search:unitSelected', unitResponse.result);
                this.close();
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // Track by function for ngFor loops
    // -----------------------------------------------------------------------------------------------------
    removeSelection(): void {
        this.unitId = null;
        this._storageService.remove('selectedUnitId', StorageLocationEnum.local);
    }

    // -----------------------------------------------------------------------------------------------------
    // On destroy
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
