export const faqCategoriesData = [
    {
        id: '1',
        slug: 'most-asked',
        title: 'Most asked'
    },
    {
        id: '2',
        slug: 'general-questions',
        title: 'General Questions'
    },
    {
        id: '3',
        slug: 'licenses',
        title: 'Licenses'
    },
    {
        id: '4',
        slug: 'payments',
        title: 'Payments'
    },
    {
        id: '5',
        slug: 'support',
        title: 'Support'
    }
];
export const faqsData = [
    {
        id: '1',
        categoryId: '1',
        question: 'Where can I do more things than the Bullhorn Web Configuration Tool allows?',
        answer: 'Go to <a class="underline text-blue-500" target="_blank" href="{{BULLHORNURL}}">Bullhorn Web</a> to do more things than this tool allows.'
    },
    {
        id: '2',
        categoryId: '1',
        question: 'Where can I apply templates or set group commands?',
        answer: 'Go to <a class="underline text-blue-500" target="_blank" href="{{BULLHORNURL}}">Bullhorn Web</a> to apply templates or set group commands'
    }
];
