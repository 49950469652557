import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'Readings'})
export class ReadingsPipe implements PipeTransform {
    transform(value: string, args: any[] = []): string {
        switch (value) {
            case 'ac': {
                return 'AC';
            }
            case 'dc': {
                return 'DC';
            }
            case 'instant_off': {
                return 'Instant Off';
            }
            case 'enabled': {
                return 'Enabled';
            }
            case 'accumulatorEnabled': {
                return 'Accumulated';
            }
            default: {
                return 'Unknown Setting';
            }
        }
    }
}
