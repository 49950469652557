import { ModuleWithProviders, NgModule } from '@angular/core';
import { CoreConfigService } from '@core/services/config/config.service';
import { CORE_APP_CONFIG } from '@core/services/config/config.constants';

@NgModule()
export class CoreConfigModule
{
    /**
     * Constructor
     */
    constructor(private _coreConfigService: CoreConfigService)
    {
    }

    /**
     * forRoot method for setting user configuration
     *
     * @param config
     */
    static forRoot(config: any): ModuleWithProviders<CoreConfigModule>
    {
        return {
            ngModule : CoreConfigModule,
            providers: [
                {
                    provide : CORE_APP_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
