import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AuthModule} from 'app/core/modules/auth.module';
import {IconsModule} from 'app/core/modules/icons.module';

@NgModule({
    imports: [
        AuthModule,
        IconsModule
    ]
})
export class CoreModule {
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
