<div class="flex flex-row items-center justify-start px-0.5 {{rowColor}}">

    <!--------------------------------------------------------------->
    <!-- Toggle Button -->
    <!--------------------------------------------------------------->
    <div class="font-medium w-11 h-11">
        <div (click)="toggleReading.emit()"
             class="flex flex-0 flex-col items-center justify-center w-11 h-11 {{toggleButtonBackgroundColor}} rounded cursor-pointer shadow-lg">
            <div id="{{toggleButtonId}}" class="{{toggleButtonTextSize}}">{{toggleButtonTitle}}</div>
            <div class="{{toggleButtonTextSize}}" *ngIf="toggleButtonSubTitle">{{toggleButtonSubTitle}}</div>
        </div>
    </div>

    <!--------------------------------------------------------------->
    <!-- Datapoint Name & Icon -->
    <!--------------------------------------------------------------->
    <div class="flex flex-row justify-between pl-2 pt-4 pb-4 w-full cursor-pointer" (click)="toggleSideBar.emit()">
        <div class="flex flex-row text-center">
            <div class="font-medium {{datapointTextColor}}">
                {{dataPointName}}
            </div>
            <pending-indicator *ngIf="showPending" [show]="showPending"></pending-indicator>
        </div>
        <div class="ml-auto cursor-pointer reading-toggle-container">
            <mat-icon class="text-hint" [svgIcon]="'mat_outline:navigate_next'"></mat-icon>
        </div>
    </div>
</div>
