<!-------------------------------------------->
<!-- Bar search -->
<!-------------------------------------------->
<ng-container id="bar-search-container" *ngIf="appearance === 'bar'">
    <button id="bar-search-icon-button" type="button"
            mat-icon-button
            *ngIf="!opened"
            (click)="open()">
        <mat-icon [svgIcon]="'mat_outline:search'"></mat-icon>
    </button>
    <div class="absolute inset-0 flex items-center flex-shrink-0 z-99 bg-card"
         *ngIf="opened"
         @slideInTop
         @slideOutTop>
        <mat-icon
            class="absolute ml-6 sm:ml-8"
            [svgIcon]="'mat_outline:search'"></mat-icon>
        <input id="bar-search-input"
               class="w-full h-full px-16 sm:px-18"
               [formControl]="searchControl"
               [matAutocomplete]="matAutocomplete"
               [placeholder]="'Search unit by name or serial number'"
               (keydown)="onKeydown($event)"
               #barSearchInput>
        <mat-autocomplete id="bar-search-auto-complete-container"
                          class="max-h-128 sm:px-2 border-t rounded-b shadow-md"
                          [disableRipple]="true"
                          #matAutocomplete="matAutocomplete">
            <mat-option id="bar-search-no-results-found-container"
                        class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                        *ngIf="resultSets && !resultSets.length">
                No results found!
            </mat-option>
            <ng-container id="bar-search-results-container" *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <ng-container *ngFor="let result of resultSet.results; trackBy: trackByFn">
                    <mat-option (click)="selectSearchItem(result)"
                                class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover">
                        <!-- Units -->
                        <ng-container *ngIf="resultSet.id === 'units'">
                            <ng-container *ngTemplateOutlet="unitResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
        <button id="bar-search-close-button" type="button"
                class="absolute top-1/2 right-5 sm:right-7 flex-shrink-0 w-10 h-10 -mt-5"
                mat-icon-button
                (click)="close()">
            <mat-icon [svgIcon]="'mat_outline:close'"></mat-icon>
        </button>
    </div>
</ng-container>

<!-------------------------------------------->
<!-- Basic search -->
<!-------------------------------------------->
<ng-container id="basic-search-container" *ngIf="appearance === 'basic'">
    <div class="w-full sm:min-w-80">
        <mat-form-field class="core-mat-no-subscript w-full">
            <mat-icon
                matPrefix
                [svgIcon]="'mat_outline:search'"></mat-icon>
            <input id="basic-search-input"
                   matInput
                   [formControl]="searchControl"
                   [matAutocomplete]="matAutocomplete"
                   [placeholder]="'Search unit by name or serial number'"
                   (keydown)="onKeydown($event)">
        </mat-form-field>
        <mat-autocomplete id="basic-search-auto-complete-container"
                          class="max-h-128 mt-1 rounded"
                          [disableRipple]="true"
                          #matAutocomplete="matAutocomplete">
            <mat-option id="basic-search-no-results-found-container"
                        class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                        *ngIf="resultSets && !resultSets.length">
                No results found!
            </mat-option>
            <ng-container id="basic-search-results-container" *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <ng-container *ngFor="let result of resultSet.results; trackBy: trackByFn">
                    <mat-option (click)="selectSearchItem(result)"
                                class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover">
                        <!-- Units -->
                        <ng-container *ngIf="resultSet.id === 'units'">
                            <ng-container *ngTemplateOutlet="unitResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
    </div>
</ng-container>

<!-------------------------------------------->
<!-- Unit search result template -->
<!-------------------------------------------->
<ng-template id="search-template-container"
             #unitResult
             let-result>
    <div class="flex flex-col">
        <div
            class="truncate leading-normal font-bold"
            [innerHTML]="result.serialNumber"></div>
        <div class="truncate leading-normal text-sm text-secondary">
            {{result.clientUnitName}}
        </div>
    </div>
</ng-template>
