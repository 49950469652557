import { DataPointEnum } from "app/core/enums/data-point.enum";

export class ChannelScalling
{
    scale_min_from: number;
    scale_max_from: number;
    scale_min_to: number;
    scale_max_to: number;

    constructor(scale_min_from: number, scale_max_from: number, scale_min_to: number, scale_max_to: number) 
    {
        this.scale_min_from = scale_min_from;
        this.scale_max_from = scale_max_from;
        this.scale_min_to = scale_min_to;
        this.scale_max_to = scale_max_to;    
    }
}

export class ChannelModel 
{
    channelId: number;
    channelName: string;

    constructor(channelId: number) 
    {
        this.channelId = channelId;
        this.channelName = 'CH ' + channelId;
    }
}

export class AnalogChannelModel extends ChannelModel
{
    analogACDataPointId: number;
    analogDCDataPointId: number;

    // if true, the channel has two types of scaling: V or mV.
    multipleScalling: boolean;
    batterySaverAlert: boolean;

    channelScalling: ChannelScalling[];

    constructor(channelId: number, analogACDataPointId: number, analogDCDataPointId: number, multipleScalling: boolean, batterySaverAlert: boolean, channelScalling: ChannelScalling[]) {
        super(channelId);
        this.analogACDataPointId = analogACDataPointId;
        this.analogDCDataPointId = analogDCDataPointId;
        this.multipleScalling = multipleScalling;
        this.batterySaverAlert = batterySaverAlert;
        this.channelScalling = channelScalling;
    }
}

export class DigitalChannelModel extends ChannelModel
{
    digitalInputDataPointId: number;

    constructor(channelId: number, digitalInputDataPointId: number) {
        super(channelId);
        this.digitalInputDataPointId = digitalInputDataPointId;
    }
}

// 510

const RM510ChannelScalling = [
    new ChannelScalling(-250000, 250000, -1000000, 1000000)
];

export const RM510AnalogChannels = [
    new AnalogChannelModel(1, DataPointEnum.analog1AC, DataPointEnum.analog1DC, true, false, RM510ChannelScalling),
    new AnalogChannelModel(2, DataPointEnum.analog2AC, DataPointEnum.analog2DC, true, false, RM510ChannelScalling),
    new AnalogChannelModel(3, DataPointEnum.analog3AC, DataPointEnum.analog3DC, true, false, RM510ChannelScalling),
    new AnalogChannelModel(4, DataPointEnum.analog4AC, DataPointEnum.analog4DC, true, false, RM510ChannelScalling)
];

export const RM510DigitalChannels = [
    new DigitalChannelModel(1, DataPointEnum.digitalInput5),
    new DigitalChannelModel(2, DataPointEnum.digitalInput6),
];

// 520

const RM520ChannelScalling = [
    new ChannelScalling(-100, 100, -1000000, 1000000),
    new ChannelScalling(-100000, 100000, -1000000, 1000000)
];

export const RM520AnalogChannels = [
    new AnalogChannelModel(1, DataPointEnum.analog1AC, DataPointEnum.analog1DC, false, true, RM520ChannelScalling),
    new AnalogChannelModel(2, DataPointEnum.analog2AC, DataPointEnum.analog2DC, false, true, RM520ChannelScalling),
    new AnalogChannelModel(3, DataPointEnum.analog3AC, DataPointEnum.analog3DC, true, true, RM520ChannelScalling),
];

// 540

const RM540ChannelScalling = [
    new ChannelScalling(-100, 100, -1000000, 1000000),
    new ChannelScalling(-100000, 100000, -1000000, 1000000)
];

export const RM540AnalogChannels = [
    new AnalogChannelModel(1, DataPointEnum.analog1AC, DataPointEnum.analog1DC, false, true, RM540ChannelScalling),
    new AnalogChannelModel(2, DataPointEnum.analog2AC, DataPointEnum.analog2DC, false, true, RM540ChannelScalling),
];

/**
 * 65	RM510C
 * 67	RM510S
 * 68	RM520C
 * 69	RM520S
 * 70	RM530C
 * 71	RM530S
 * 72	RM540C
 * 73	RM540S
 */
export const RM5AnalogChannels: Map<number, AnalogChannelModel[]> = new Map<number, AnalogChannelModel[]>
([
    [65, RM510AnalogChannels],
    [67, RM510AnalogChannels],
    [68, RM520AnalogChannels],
    [69, RM520AnalogChannels],
    [70, []],
    [71, []],
    [72, RM540AnalogChannels],
    [73, RM540AnalogChannels]
]);

export const RM5DigitalChannels: Map<number, DigitalChannelModel[]> = new Map<number, DigitalChannelModel[]>
([
    [65, RM510DigitalChannels],
    [67, RM510DigitalChannels],
    [68, []],
    [69, []],
    [70, []],
    [71, []],
    [72, []],
    [73, []]
]);