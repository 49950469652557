import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'channelPipe'})
export class ChannelPipe implements PipeTransform {
    transform(value: string, args: any[] = []): string {
        if (value && value === 'ac') {
            return 'AC';
        }
        if (value && value === 'dc') {
            return 'DC';
        }
        if (value && value === 'instant_off') {
            return 'Instant Off';
        }
    }
}
