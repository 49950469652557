import {Component, Input, OnInit} from '@angular/core';
import {Unit} from '../../../core/models/unit.model';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
    @Input() breadcrumbTitle: string;
    @Input() description: string;
    @Input() unit: Unit;
    @Input() instanceId: number;
    @Input() showInstanceId: boolean = false;
    
    constructor() {
    }

    ngOnInit(): void {
    }
}
