import { NgModule } from '@angular/core';
import { CoreSplashScreenService } from '@core/services/splash-screen/splash-screen.service';

@NgModule({
    providers: [
        CoreSplashScreenService
    ]
})
export class CoreSplashScreenModule
{
    /**
     * Constructor
     */
    constructor(private _coreSplashScreenService: CoreSplashScreenService)
    {
    }
}
